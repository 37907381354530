
import React, { useState, useMemo, useEffect } from 'react';
import Pagination from '../common/pagination';
import data from '../../data/mock-data.json';

import { Routes, Route, useNavigate } from 'react-router-dom';
import api from '../../service/api';

import { useTranslation } from 'react-i18next';

function Orderlist(props) {

    const { t, i18n } = useTranslation();

    let PageSize = props.pagesize;

    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(0);
    const [isData, setIsData] = useState(0);


    const navigate = useNavigate();

    const navigatetodetail = (Id) => {

        navigate(`/orderindividual/${Id}`, { state: { id: Id } });
    };


    const changestatus = (Id,Status) => {
        const data =  {
            Id:Id,
            Status:Status
          };
          try { 
            api.generic('POST','/general/orders/changestatus',data)             
            .then((response)=>{
                console.log(response)
                if(response.data.success==true) {
                    window.location.reload(); 
                } else {
                alert("Technical issue. Please Try Again");
                }
            })
            .catch((error) => {
              console.log(error)
                alert("Technical issue. Please Try Again");
            })
        } catch (error) {
          console.log(error)
            alert("Technical issue. Please Try Again");
        }
    };

    const currentTableData = useMemo(() => {
        console.log("worked")

        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;

        setIsData(isData + 1);
        if (props.isError == 1) {
            setIsLoading(1);
        }

        if (isData >= 1) {
            setIsLoading(1);
        }

        return props.list.data ? props.list.data.slice(firstPageIndex, lastPageIndex) : [];


    }, [currentPage, props]);

    return (
        <div className="boxs col-xs-12">
     <h5> 
           <div class="filters-top col-xs-12 col-md-5">
              {/*  <form action="">
               <input type="text" class="search" placeholder="Search"/>
               </form>  */ }
           </div>
           
           <div class="filters-top col-xs-12 col-md-7">
            {/*
               <form action="">
                   <div></div>
                   <input type="date" class="date" placeholder="Select Date"/>
                       <select class="status">
                           <option value="">Status</option>
                           <option value="">Yas</option>
                           <option value="">No</option>
                       </select>
               </form>
    */ }
           </div>

       </h5>



            <table width="100%" class="table responsive-table">
            <thead> <tr>
            <th> Order ID</th>
                   <th> BC</th>
                   <th>{t("Branch")}</th>
                   <th> {t("OrderDate")}</th>
                   <th>{t("DeliveryType")}</th>
                   <th> {t("Status")}</th>
                   <th> {t("PaymentStatus")}</th>
                   <th> {t("Totalamount")}</th>
                   <th> </th>
                        </tr>
                    </thead>
                <tbody>



                    {currentTableData.map(data => {
                        return (

                            <tr>
                            <td class="bold">00{data.Id}</td>
                            <td class="bold">{data.BcName}</td>     
                            <td class="bold">{data.BranchNameEn}</td>     
                            <td>
                            {new Intl.DateTimeFormat('en-GB', { 
                month: 'long', 
                day: '2-digit',
                year: 'numeric', 
            }).format(new Date(data.CreatedOn))}
                            </td>                            
                            <td>{data.DeliveryType}</td>                            
                            <td><span class={data.Status.toLowerCase()}>{data.Status}</span></td>    
                            {data.PaymentStatus == 'Unpaid' ? (<td><span class="unpaid">{data.PaymentStatus }</span></td>  ) : (<td><span class="paid">{data.PaymentStatus }</span></td>  )}                                                    
                                                                                
                            <td class="bold">SAR {data.TotalAmount}</td>                            
                            <td>
                                <div class="dropdown">
                                    <i class="fa fa-ellipsis-h" id="dropdown" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    </i>
                                    <ul class="dropdown-menu" aria-labelledby="dropdown">
                                      <li><a  onClick={() => navigatetodetail(data.Id)}> {data.Status == "Submitted"? <>View to Confirm Order</>:<>View</> }</a></li>
                                      {data.Status == "Submitted" ? 
<></>
  : data.Status == "Confirmed" ?  
  <li><a  onClick={() => changestatus(data.Id,'In-Transit')}>In-Transit</a> </li>
  : data.Status == "In-Transit" ?  
  <li><a  onClick={() => changestatus(data.Id,'Delivered')}>Mark as Delivered</a> </li>         
  : data.Status == "Delivered" ?  <></> 
  : data.Status == "Cancelled" ?  <></> :<></>
                                      }
                                    </ul>
                                </div>
                            </td>
                        </tr>

                        );
                    })}

                </tbody>
            </table>

            {isLoading == 0 ? <h1 align='center'>Loading..</h1> : null}



            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={props.total}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
            />
        </div>


    );
}

export default Orderlist;
