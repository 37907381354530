import React, { useState, useEffect } from 'react';
import List from '../../component/businesscustomer/businesscustomerlist';
import api from '../../service/api';

import { useTranslation } from "react-i18next";

function Businesscustomers() { 


    const [list, setList] = useState([]);
    const [listCategory, setListCategory] = useState([]);
    const [listOther, setListOther] = useState([]);
    const [total, setTotal] = useState(0);
    const [isError, setIsError] = useState(0);
        
  const { i18n,t } = useTranslation();

    useEffect(() => {
            

        const fetchList = async () => {
            try {
                api.generic('GET','/bc/all')               
                .then((response)=>{
                    
                    console.log(response.data);
                    setList(response.data);
                    setTotal(response.data.data.length);
                })
                .catch((error) => {
                    setIsError(1);
                })
            } catch (error) {
                setIsError(1);
            }
        };


        fetchList();



        
    }, []);

    return ( 
      <>
      <h1>{t("ManageBusinessCustomers")}
        { /*    <button class="add-product"><span>+</span> Add new B.C</button> */ }
          </h1>
          <h6>* {t("BCCanBeCreatedFromRegistrationPageOfBC")}.<a href="https://b2bbc.b2b.sa/signup" target="about:blank">
{t("ClickHere")}</a></h6>

     <div class="product col-xs-12">
        
        
     <List list={list} total={total} pagesize={10} isError={isError} />
     </div>
   </>
    ); 

} 

export default Businesscustomers; 
