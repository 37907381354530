import { Outlet, Navigate } from 'react-router-dom'

const PrivateRoutes = () => {
    let  tokenExist = localStorage.getItem("accessToken") == null ? false : true;
    let auth = {'token':tokenExist}
    return(
        auth.token ? <Outlet/> : <Navigate to="/login"/>
    )
}

export default PrivateRoutes