
import React, { useState, useMemo, useEffect } from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';



   

function Nofavourite(props) {
   
    const navigate = useNavigate();

  const navigateToAvailableProducts = () => {
    navigate('/availableproducts');
  };


    return (
        <div class="empty">
        <p> There is no fav list now. Branches Haven't created yet</p> {/*, but you <br/> can create one</p>
      
        <button onClick={navigateToAvailableProducts} class="add-product"><span>+</span> Create Fav List</button>
        */}
    </div>

    );
}

export default Nofavourite;
