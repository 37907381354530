import React, { useState, useEffect } from 'react';
import ProductList from '../../component/product/productlist';
//import api from '../service/api';
import { useProductStore } from '../../store/useProductStore';
import { useDashboardStore } from '../../store/useDashboardStore';

import { useParams } from 'react-router';

import { useRef } from 'react';
import { RESOURCE_URL } from "../../config/datasource";
import api from '../../service/api';

import { useTranslation } from "react-i18next";

function ProductDetail() {
    const { i18n, t } = useTranslation();

    const [list, setList] = useState([]);
    const [listCategory, setListCategory] = useState([]);
    const [listOther, setListOther] = useState([]);
    const [total, setTotal] = useState(0);
    const [isError, setIsError] = useState(0);

    const [formData, setFormData] = useState({});
    const inputRef = useRef(null);
    const [dataId, setDataId] = useState(0);

    const [type, setType] = useState('');
    const fallbackSrc = RESOURCE_URL + "/images/1_logo.png";
    const [isImg, setIsImg] = useState(0);


    const { itemid } = useParams();

    const { id } = useDashboardStore((state) => ({
        id: state.id,
    }));

    const { products, productcategory, getProducts, getProductCategory } = useProductStore((state) => ({
        products: state.products,
        productcategory: state.productcategory,
        getProductCategory: state.getProductCategory,
        getProducts: state.getProducts,
    }));



    const handleUpload = (Id, type) => {
        // 👇️ open file input box on click of another element
        setDataId(Id)
        setType(type)

        inputRef.current.click();
    };


    function getExtension(filename) {
        return filename.split('.').pop()
    }


    const handleFileChange = event => {
        console.log(dataId)
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }

        console.log('fileObj is', fileObj);

        // 👇️ reset file input
        event.target.value = null;

        // 👇️ is now empty
        console.log(event.target.files);

        // 👇️ can still access file object here
        console.log(fileObj);
        console.log(fileObj.name);
        if (type == "img" && getExtension(fileObj.name.toLowerCase()) != "png") {
            alert("File extension should be .png " + "Currently extension is " + "." + getExtension(fileObj.name.toLowerCase()))
            return false;
        }

        const formData = new FormData();
        formData.append("Id", dataId);
        formData.append("Type", type);
        formData.append("file", fileObj);
        formData.append("name", fileObj.name);


        try {

            api.generic('POST', '/general/uploadproduct', formData)
                .then((response) => {

                    console.log(response.data);
                    if (response.data.success == true) {

                        alert("Uploaded successfully")
                        window.location.reload();
                    }

                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
        }


    };

    async function exists(url, type) {

        const data = {
            path: url
        };

        try {
            api.generic('POST', 'general/fileexist', data)
                .then((response) => {
                    console.log(response.data)
                    console.log(response.data.success)
                    if (response.data.success) {
                        setIsImg(1)
                    }

                })
        } catch (error) { setIsImg(0) }
    }




    useEffect(() => {
        exists('/product/' + itemid + '_img.png', 'img')
    }, [formData]);


    useEffect(() => {
        if (products && products.products && products.products.length > 0) {
            //nothing 
        } else {
            console.log('product called')
            getProducts();
        }

        if (productcategory && productcategory.categories && productcategory.categories.length > 0) {
            //nothing
        } else {
            console.log("category called")
            getProductCategory();
        }
    }, [getProductCategory, getProducts])

    useEffect(() => {

        if (products && products.products && products.products.length > 0) {
            console.log(itemid)
            console.log(products?.products?.filter(value => value.id == itemid))
            setList(products?.products?.filter(value => value.id == itemid));
            setTotal(1)
        }

        if (productcategory && productcategory.categories && productcategory.categories.length > 0) {
            setListCategory(productcategory);
        }

    }, [products, productcategory])




    useEffect(() => {

        const json1 = [{
            "status": [{ id: 1, name: "Yes" },
            { id: 2, name: "No" }]
        }];

        const json2 = [{
            "categories": [{ id: 3, test: 6 },
            { id: 4, test: 7 },
            { id: 5, test: 8 },
            { id: 6, test: 9 },
            { id: 7, test: 10 }]
        }];

        var finalObj = json1.concat(json2)

        setListOther(finalObj)


    }, []);



    return (
        <>
            <h1>  {t("ProductDetails")} </h1>
            <div class="product col-xs-12">
                <div class="boxs col-xs-12">
                    <h5> <span>{t("Details")}</span></h5>
                    {list.map((data, index) => (
                        <div class="form-add col-xs-12">
                            <div class="field col-xs-12 col-md-4">
                                <label>{t("ProductNameEnglish")}</label>
                                <input type="text" class="input" placeholder="Product name In english" value={data.name_en} />
                            </div>

                            <div class="field col-xs-12 col-md-4">
                                <label>{t("ProductNameArabic")}</label>
                                <input type="text" class="input" placeholder="اسم المنتج باللغه العربيه" value={data.name_ar} />
                            </div>

                            <div class="field col-xs-12 col-md-4">
                                <label>SKU</label>
                                <input type="text" class="input" placeholder="SKU" value={data.sku} />
                            </div>

                            <div class="field col-xs-12 col-md-4">
                                <label>{t("BarCode")}</label>
                                <input type="text" class="input" placeholder="Bar Code" value={data.barcode} />
                            </div>

                            <div class="field col-xs-12 col-md-4">
                                <label>{t("ProductCategory")}</label>
                                {listCategory?.categories.filter(cat => cat.id == data.category_id).map(fillcat => (

                                    <input type="text" class="input" placeholder="Product Category" value={fillcat.name} />
                                ))}
                            </div>

                            <div class="field col-xs-12 col-md-4">
                                <label>{t("UnitOfMeasure")}</label>

                                <input type="text" class="input" placeholder="Unit of measure" value={data.unit} />


                            </div>
                            <div class="field col-xs-12 col-md-4">
                                <label>{t("Price")}</label>
                                <input type="text" class="input" placeholder="Price" value={data.selling_price} />
                            </div>
                            <div class="field col-xs-12 col-md-8">
                                <label>{t("Description")}</label>
                                <input type="text" class="input" placeholder="Description" value={data.description} />
                            </div>
                            <div class="field col-xs-12 col-md-5"><i class="plus"></i>   <b>{t("ProductImg")} (png)</b> <a onClick={() => handleUpload(itemid, "img")}>{t("Upload")}</a> |
                                {
                                    isImg ?
                                        (<><a target="about:blank" href={RESOURCE_URL + '/product/' + itemid + '_img.png'}> {t("Show")}</a></>) : (<></>)
                                }

                            </div>

                        </div>
                    ))}

                    <input
                        style={{ display: 'none' }}
                        ref={inputRef}
                        type="file"
                        onChange={handleFileChange}
                    />
                </div>
            </div>
        </>
    );
}
export default ProductDetail;

