
import { useDashboardStore } from '../store/useDashboardStore';
import React, { useState, useEffect } from 'react';
import api from '../service/api';
import LanguageSwitcher from '../component/common/languageswitcher';



function HeaderApp() {
    
    const {id,EnFullName,ArFullName,setEnFullName,setArFullName} = useDashboardStore((state) => ({
        id: state.id,
        EnFullName: state.EnFullName,
        ArFullName: state.ArFullName,
        setEnFullName: state.setEnFullName,
        setArFullName: state.setArFullName,
      }));
      console.log("header")




  
useEffect(() => {
    if(EnFullName.length==0) {
    try {
        api.generic('GET','/user/profile')               
        .then((response)=>{
        setEnFullName(response.data[0].EnFullName)
        setArFullName(response.data[0].ArFullName)

        })
        .catch((error) => {
        })
    } catch (error) {
    }
}
  }, [])

  return (
    <div className="topbar col-xs-12">
  <img className="logo" src="img/logo.png" alt=""/>
  <div className="profile">      

      <div className="notification">
          <div className="notifications-box">
              <h4>Notifications</h4>
              <ul>
                  <li>
                      <img src="img/icon.png" alt=""/>
                      <h3>branch name</h3>
                      <p>Mohamed ahmed added a new Fav Products list</p>
                      <i>9/8/2023</i>
                  </li>
                  <li>
                      <img src="img/icon.png" alt=""/>
                      <h3>branch name</h3>
                      <p>Mohamed ahmed added a new Fav Products list</p>
                      <i>9/8/2023 <a href="#">View</a></i>
                  </li>
                  <li>
                      <img src="img/icon.png" alt=""/>
                      <h3>branch name</h3>
                      <p>Mohamed ahmed added a new Fav Products list</p>
                      <i>9/8/2023</i>
                  </li>
              </ul>
          </div>
      </div>

      <div className="user-edit">
       <LanguageSwitcher />  <img src="img/user.png" alt=""/>
          {EnFullName} 
          <i className="fa fa-angle-down" aria-hidden="true"></i>
          <ul className="link">
              <li><a href="#">Link profile</a></li>
              <li><a href="#">Link profile</a></li>
              <li><a href="#">Link profile</a></li>
              <li><a href="#">Link profile</a></li>
          </ul>
      </div>
  </div>
</div>
  );
}

export default HeaderApp;
