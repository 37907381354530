import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./usepagination";
import "./pagination.scss";
const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
  
    if(currentPage!=totalCount) {
    onPageChange(currentPage + 1);
    }
  };

  const onPrevious = () => {
    if(currentPage!=1) {
    onPageChange(currentPage - 1);
    }
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <>


            <div class="nav-page col-xs-12">
                
            <a className={classnames("pagination-item","previous", {
          disabled: currentPage === 1
        })} onClick={onPrevious} >Previous</a>
    <ul>
    
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return <li key="dots" className="pagination-item dots">&#8230;</li>;
        }

        return (
            <li key={pageNumber}>
          <a
            className={classnames( {
                active: pageNumber === currentPage
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </a></li>
        );
      })}
      </ul>
      <a className={classnames("pagination-item","next", {
          disabled: currentPage === lastPage
        })} onClick={onNext} >Next</a>

    </div>
    
    </>
  );
};

export default Pagination;
