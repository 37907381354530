import { Outlet, Link } from "react-router-dom";
import React from 'react';

const LayoutLogin = () => {

    
 
  return (
    <>
                <Outlet />
    </>
  )
};

export default LayoutLogin;