import React, {useState}  from 'react'
import { useTranslation } from "react-i18next";
import api from '../../service/api';

const AddProduct = () => {
    
    const { t, i18n } = useTranslation();
    const [selectedFile, setSelectedFile] = useState(null);
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            alert('Please select a file first!');
            return;
        }
        const formData = new FormData();
        formData.append('file', selectedFile);
        try {
            await api.generic('POST', "/general/product/upload", formData).then((res) => {
                console.log(res);
            })
        } catch (error) {
            console.log(error);
        }

    }

    return (
        <>
            <div className="row">
                <div className='col-md-12'>
                    <h1>{t("AddProducts")}</h1>
                    <div className='boxs row'>
                        <div className="field col-md-6">
                            <label>{t("Name")}</label>
                            <input type="text"  name="" className="input" required/>
                        </div>
                        <div className="field col-md-6">
                            <label>{t("Price")}</label>
                            <input type="text" name="" className="input" required />
                        </div>
                        <div className="field col-md-6">
                            <label>{t("Type")}</label>
                            <input type="text" name="" className="input" required/>
                        </div>
                        <div className="field col-md-6">
                            <label>{t("Cost")}</label>
                            <input type="text" name="" className="input" required/>
                        </div>
                        <h1><button className="add-product">Add Products</button></h1>
                    </div>
                    <div className='boxs row'>
                        <div className="field col-md-2">
                            <input type="file" onChange={handleFileChange} className="input" required />
                        </div>
                        <div className="col-md-2 align-content-center align-items-center">
                            <button className="btn_custom" onClick={handleUpload}>Upload</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddProduct;