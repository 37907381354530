import React, { useState, useEffect } from 'react';
import List from '../../component/orders/orderlist';
import api from '../../service/api';
import {Routes, Route, useNavigate} from 'react-router-dom';


import { useTranslation } from "react-i18next";


function Orders() { 

    const { i18n,t } = useTranslation();

    const [list, setList] = useState([]);
    const [listCategory, setListCategory] = useState([]);
    const [listOther, setListOther] = useState([]);
    const [total, setTotal] = useState(0);
    const [isError, setIsError] = useState(0);
        
    
    const [open, setOpen] = useState(0);
    const [submitted, setSubmitted] = useState(0);
    const [completed, setCompleted] = useState(0);
    const [cancelled, setCancelled] = useState(0);
    const [delivered, setDelivered] = useState(0);
    
    const navigate = useNavigate();

    
    const navigatetoneworder = () => {
        navigate('/ordernew');
    }

    useEffect(() => {
            

        const fetchList = async () => {
            try {
                await api.generic('GET','/general/orders')               
                .then((response)=>{
                    console.log(response.data);
                    setList(response.data);
                    setTotal(response.data.data.length);
                    
                    setSubmitted(response.data.data.filter(value => value.Status === 'Submitted').length);
                    setCompleted(response.data.data.filter(value => value.Status === 'Completed').length);
                    setCancelled(response.data.data.filter(value => value.Status === 'Cancelled').length);
                    setDelivered(response.data.data.filter(value => value.Status === 'Delivered').length);
                    
                    setOpen(response.data.data.filter(value => value.Status != 'Cancelled' ).length - response.data.data.filter(value => value.Status === 'Completed').length)

                })
                .catch((error) => {
                    setIsError(1);
                })
            } catch (error) {
                setIsError(1);
            }
        };


        fetchList();



        
    }, []);

    return ( 
      <>
     <h1>{t("Insights")}</h1>

<div class="counts col-12-xs">
   <div class="boxs insights">
       <img src="img/counts-1.png" alt=""/>
       <div>{t("Opened")}</div>
       <h2>{open}</h2>
       <div class="foot">
           <img src="img/down.png" alt=""/> <span class="down">0.00%</span>  From Jun
       </div>
   </div>
   <div class="boxs insights">
       <img src="img/counts-2.png" alt=""/>
       <div>{t("Submitted")}</div>
       <h2>{submitted}</h2>
       <div class="foot">
           <img src="img/up.png" alt=""/> <span class="up">0.00%</span> From Jun
       </div>
   </div>
   <div class="boxs insights">
       <img src="img/counts-3.png" alt=""/>
       <div>{t("Completed")}</div>
       <h2>{completed}</h2>
       <div class="foot">
           <img src="img/up.png" alt=""/> <span class="up">0.00%</span> From Jun
       </div>
   </div>
   <div class="boxs insights">
       <img src="img/counts-4.png" alt=""/>
       <div>{t("Cancelled")}</div>
       <h2>{cancelled}</h2>
       <div class="foot">
           <img src="img/down.png" alt=""/> <span class="down">0.00%</span> From Jun
       </div>
   </div>
</div>

<h1>{t("ManageOrders")}
   <button  class="create" onClick={navigatetoneworder}>{t("CreateNewOrder")}</button>
</h1>


     <div class="product col-xs-12">
        
        
     <List list={list} total={total} pagesize={15} isError={isError} />
     </div>
   </>
    ); 

} 

export default Orders; 