import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState } from 'react';
import Layout from "./page/layout/Layout";
import LayoutLogin from "./page/layout/LayoutLogin";
import Dashboard from "./page/dashboard/Dashboard";
import Products from "./page/products/Products";
import ProductDetail from "./page/products/Productdetail";
import AddProduct from "./page/products/AddProduct";
import NoPage from "./page/NoPage";
import Favourites from "./page/rfq/Branchfavourites";
import Quotes from "./page/rfq/Quotes";
import Darkstores from './page/store/Darkstores';
import Finances from './page/finance/Finances';
import Orders from './page/orders/Orders';
import Branches from './page/branch/Branches';
import BranchNew from './page/branch/Branchnew';
import BusinessCustomersBranches from './page/branch/Businesscustomersbranches';


import Branchuser from './page/branch/Branchuser';
//import BranchFavourites from './page/branch/Branchfavourites';
import BranchFavouriteIndividual from './page/rfq/Branchfavouriteindividual';
import B2BUsers from './page/b2busers/B2Busers';
import Businesscustomers from './page/bc/Businesscustomers';
import Login from './page/login/Login';
import Companies from './page/company/Companies';

import AvailableProducts from "./page/rfq/Availableproducts";

import PrivateRoutes from './service/privateroutes'

import OrderNew from './page/orders/Ordernew';
import AvailableProductsNewOrder from "./page/orders/Availableproductsneworder";

import OrderSummary from './page/orders/Ordersummary';
import OrderIndividual from './page/orders/OrderIndividual';

import "./config/language";

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<LayoutLogin />}>
                    <Route path="/login" element={<Login />} />
                </Route>

                <Route element={<PrivateRoutes />}>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Dashboard />} />
                        <Route path="/products" element={<Products />} />
                        <Route path="/productdetail/:itemid" element={<ProductDetail />} />
                        <Route path="/add-product" element={<AddProduct />} />

                        <Route path="/quotes" element={<Quotes />} />
                        <Route path="/favourites" element={<Favourites />} />
                        <Route path="/orders" element={<Orders />} />
                        <Route path="/ordernew" element={<OrderNew />} />
                        <Route path="/finances" element={<Finances />} />
                        <Route path="/darkstores" element={<Darkstores />} />
                        <Route path="/branches" element={<Branches />} />
                        <Route path="/branchnew" element={<BranchNew />} />
                        <Route path="/businesscustomersbranches/:itemid" element={<BusinessCustomersBranches />} />
                        <Route path="/branchuser/:itemid" element={<Branchuser />} />
                        <Route path="/branchfavouriteindividual/:itemid" element={<BranchFavouriteIndividual />} />
                        <Route path="/b2busers" element={<B2BUsers />} />
                        <Route path="/businesscustomers" element={<Businesscustomers />} />
                        <Route path="/companies" element={<Companies />} />
                        <Route path="/availableproducts" element={<AvailableProducts />} />
                        <Route path="/availableproductsneworder" element={<AvailableProductsNewOrder />} />
                        <Route path="/ordersummary" element={<OrderSummary />} />
                        <Route path="/orderindividual/:itemid" element={<OrderIndividual />} />

                        <Route path="*" element={<NoPage />} />
                    </Route>
                </Route>
            </Routes>
        </div>
    );
}

export default App;