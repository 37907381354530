import {Routes, Route, useNavigate} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import api from '../../service/api';
import Nofavourite from '../../component/favourite/nofavourite';
import List from '../../component/favourite/branchfavouritelist';

import { useTranslation } from 'react-i18next';
import helperapi from '../../service/common';
import OrderProductCard from '../../component/orders/orderproductcard';

import { useParams } from 'react-router';

import {useLocation} from 'react-router-dom';


import { useProductStore } from '../../store/useProductStore';



import { Tab } from '@headlessui/react'


function OrderIndividual(props) {
    const { t, i18n } = useTranslation();

    
  const [isError, setIsError] = useState(0);
  const [isLoading, setIsLoading] = useState(0);

  const navigate = useNavigate();


  const location = useLocation();
  const { itemid } = useParams();

  
const [list, setList] = useState([]);
const [listproduct, setListProduct] = useState([]);
const [total, setTotal] = useState(0);

const [deliveryDays, setDeliveryDays] =  useState(0);
const [paymentStatus, setPaymentStatus] =  useState('Unpaid');
  
const showorders = () => {
    navigate('/orders');
}

const confirmPayment = () => {
    const data =  {
        Id:itemid
      };
      try { 
        api.generic('POST','/general/orders/changepaymentstatus',data)             
        .then((response)=>{
            console.log(response)
            if(response.data.success==true) {
                window.location.reload(); 
            } else {
            alert("Technical issue. Please Try Again");
            }
        })
        .catch((error) => {
          console.log(error)
            alert("Technical issue. Please Try Again");
        })
    } catch (error) {
      console.log(error)
        alert("Technical issue. Please Try Again");
    }
}


const cancelOrder = () => {

   
    const data =  {
        Id:itemid,
        ExpDeliveryDay:deliveryDays
      };
      try { 
        api.generic('POST','/general/orders/cancelorder',data)             
        .then((response)=>{
            console.log(response)
            if(response.data.success==true) {
                window.location.reload(); 
            } else {
            alert("Technical issue. Please Try Again");
            }
        })
        .catch((error) => {
          console.log(error)
            alert("Technical issue. Please Try Again");
        })
    } catch (error) {
      console.log(error)
        alert("Technical issue. Please Try Again");
    }
}

const confirmOrder = () => {

    if(deliveryDays<=0) {
        alert("Please provide delivery days. Should be more than 0")

    } else {
    const data =  {
        Id:itemid,
        ExpDeliveryDay:deliveryDays
      };
      try { 
        api.generic('POST','/general/orders/confirmorder',data)             
        .then((response)=>{
            console.log(response)
            if(response.data.success==true) {
                window.location.reload(); 
            } else {
            alert("Technical issue. Please Try Again");
            }
        })
        .catch((error) => {
          console.log(error)
            alert("Technical issue. Please Try Again");
        })
    } catch (error) {
      console.log(error)
        alert("Technical issue. Please Try Again");
    }
}
}




const navigatetodetail = (Id) => {

    navigate(`/productdetail/${Id}`, { state: { id: Id } });
};


  

  const {products, productcategory, getProducts, getProductCategory} = useProductStore((state) => ({
    products: state.products,
    productcategory: state.productcategory,
    getProductCategory: state.getProductCategory,
    getProducts: state.getProducts,
  }));

  
  useEffect(() => {
    if (products && products.products && products.products.length > 0) {
        //nothing 
        } else {
            console.log('product called')
            getProducts();
        }

    if (productcategory && productcategory.categories && productcategory.categories.length > 0) {
        //nothing
    } else {
        console.log("category called")
        getProductCategory();
    }
  }, [getProductCategory, getProducts])




useEffect(() => {
   
   
    
    const fetchList = async () => {
        try {
           //api.generic('GET','/general/branchfavouritelist/' + itemid)    
            api.generic('GET','/general/orders/' + itemid)              
            .then((response)=>{
                console.log("orders")
                console.log(response.data);
                setList(response.data);
                //setTotal(response.data.data.length);
                
               
            })
            .catch((error) => {
                setIsError(1);
            })
        } catch (error) {
            setIsError(1);
        }
    };


    fetchList();


    const fetchListProduct = async () => {
        try {
            //api.generic('GET','/general/branchfavouritelist/products/' + itemid)  
            api.generic('GET','/general/orders/products/' + itemid)                
            .then((response)=>{
                
                console.log(response.data);
                setListProduct(response.data);
                setTotal(response.data.length);
            })
            .catch((error) => {
                setIsError(1);
            })
        } catch (error) {
            setIsError(1);
        }
    };


    fetchListProduct();


  setIsLoading(1)
  }, [location.state])
  
    

useEffect(() => {
if(list) {
    setDeliveryDays(list[0]?.ExpDeliveryDay)
}
}, [list])
  
  return ( 
    <>
    {isLoading == 0 ? "Loading..." : "" }

  
    <>
    <h1>{t("OrderDetails")}  


{list[0]?.Status=="Submitted" ? 
<button onClick={confirmOrder} style={{margin:"5px"}} class="add-product">Confirm Order</button> :
<></> }


{list[0]?.Status=="Submitted" ? 
<button onClick={cancelOrder} style={{margin:"5px"}} class="add-product">Cancel Order</button> :
<></> }

{list[0]?.PaymentStatus=="Unpaid" && (list[0]?.Status!="Cancelled" && list[0]?.Status!="Submitted" ) ? 
<button  style={{margin:"5px"}} class="add-product"  onClick={confirmPayment}>{t("ConfirmPayment")}</button> :
<></> }
     </h1>

     <div class="boxs order-info col-xs-12">
            <div class="box-info box-info-call col-xs-12 col-md-4">
                <div class="div-info" style={{"text-align": "left"}}>
                    <img src="img/user.png" alt=""/>
                    
                    <i class="ico-v"></i> <span>#00{list[0]?.Id} </span><br/>
                    <i class="ico-u"></i> <span>{list[0]?.BranchName} </span><br/>
                    <i class="ico-m"></i><span>{list[0]?.Email}</span>
                </div>
                <div class="div-info" style={{"text-align": "left"}}>
                    <i class="ico-ph"></i><span>{list[0]?.Mobile}</span> <br/>
                    <i class="ico-map"></i><span>{list[0]?.Address} <br/><a href={list[0]?.AddressLink} target="about:blank">{list[0]?.AddressLink}</a> </span>     
                </div>
            </div>

            <div class="box-info col-xs-12 col-md-8">
                <div class="delivery-details col-xs-12">
                    <div class="details">
                        <h3>{t("Deliverydetails")}</h3>
                        <div class="order-submitted">{t("Order")}  {t(list[0]?.Status)}</div>
                    </div>
                    <div class="details">

                  
                    <div class="details-stuts oring"> <img src="img/dollar-square.png" alt=""/>{t(list[0]?.PaymentStatus)} </div>

                        
                        <div class="details-stuts red"> <img src="img/truck-fast.png" alt=""/>{t(list[0]?.bcNameq.replace(" ",""))}</div>
                    </div>
                </div>
                
                <div class="block-details-order col-xs-12">
                    <div>
                        <h5>{t("OrderDate")}</h5>

                       {helperapi.formatdate(String(list[0]?.CreatedOn))}
                       
                    </div>
                    <div>
                        <h5>{t("ExpectedDeliveryDays")}</h5>
                       
                        {list[0]?.Status=="Submitted" ? 
                        <input type="text"  value={deliveryDays}  class="input" placeholder="Expected Delivery days" onChange={e => setDeliveryDays(e.target.value)}/> :
                        <> {list[0]?.ExpDeliveryDay}</>
  }
                    </div>
                    <div>
                        <h5>{t("DeliveryCharges")}</h5>
                        SAR {list[0]?.DeliveryCharges}
                    </div>
                    <div>
                        <h5>{t("DarkStore")}</h5>
                        {list[0]?.StoreName}
                    </div>
                </div>
                
            </div>
        </div>
  


            <div class="boxs rfq-details col-xs-12">
               
                <ul class="nav nav-tabs" role="tablist">
                    <li role="presentation" class="active"><a href="#home" aria-controls="home" role="tab" data-toggle="tab"><img src="img/products.png" alt=""/> {t("Products")}</a></li>
                    <li role="presentation"><a href="#profile" aria-controls="profile" role="tab" data-toggle="tab"><img src="img/summary.png" alt=""/> {t("Summary")}</a></li>
                </ul>
            </div>



            <div class="product col-xs-12">
            <div class="tab-content">
                <div role="tabpanel" class="tab-pane active" id="home">
                <div class="products col-xs-12">
           
     {listproduct?.map((data, idx) => (
                    <OrderProductCard qty={data.Quantity} key={idx} productid={data.ProductId} sku={data.sku} price={data.UnitPrice} name={data.name}  param={products?.products?.filter(value => value.sku === data.sku)}/>

                    ))}

</div>
                </div>
                <div role="tabpanel" class="tab-pane" id="profile">  

                 
                <div class="boxs col-xs-12"> 
                        <div class="total-vat col-xs-12">
                            <div>
                                <span>{t("TotalamountExclVAT")}:  <strong>SAR  {list[0]?.TotalOrderPrice}</strong></span>
                                <span>{t("DeliveryCharges")}: <strong>SAR {list[0]?.DeliveryCharges}</strong></span>
                                <span>{t("VATPER")}: <strong>15%</strong></span>
                            </div>
                            <div><span class="grand">{t("GrandTotal")}: <strong>{Number(Number(list[0]?.TotalAmount) ).toFixed(2)}</strong></span></div>
                        </div>

                        <table width="100%" class="table responsive-table">
                            <thead>
                                <tr>
                                    <th>{t("lineitem")} </th>
                                    <th> SKU </th>
                                    <th> {t("Product")} </th>
                                    <th> {t("Quantity")}</th>
                                    <th> {t("UnitPriceSAR")} </th>
                                    <th> {t("VAT15")} </th>
                                    <th>{t("TotalPriceIncVAT")} </th>
                                    <th> </th>
                                </tr>
                            </thead>
                            <tbody>

                                      {listproduct?.map((data, idx) => (

                                              <tr>
                                   <td class="bold">00{data.Id}</td>
                                    <td class="bold">{data.sku}</td>
                                    <td class="bold">{data.name}</td>
                                    <td>{data.Quantity} </td>
                                    <td> SAR {data.UnitPrice} </td>
                                    <td>SAR  {data.VATAmount }</td>
                                    <td>SAR {data.TotalPrice }</td>
                                    <td><a onClick={() => navigatetodetail(data.ProductId)}><i class="fa fa-eye"></i></a></td>
                                    </tr>

                                      ))}

                            
                               
                            </tbody>
                        </table>

                    </div>
                </div>
        </div>
        </div>




        
    </>
  </>
); 
}

export default OrderIndividual; 