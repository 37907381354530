
import React, { useState, useMemo, useEffect } from 'react';
import Pagination from '../common/pagination';
import data from '../../data/mock-data.json';

import helperapi from '../../service/common';


function DarkstoreList(props) {
    let PageSize = props.pagesize;

    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(0);
    const [isData, setIsData] = useState(0);



    const currentTableData = useMemo(() => {
        console.log("worked")

        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;

        setIsData(isData + 1);
        if (props.isError == 1) {
            setIsLoading(1);
        }

        if (isData >= 1) {
            setIsLoading(1);
        }

        return props.list.data ? props.list.data.slice(firstPageIndex, lastPageIndex) : [];


    }, [currentPage, props]);



    return (
        <div className="boxs col-xs-12">
        <h5> 
                    <div class="filters-top col-xs-12 col-md-6">
                    </div>

                </h5>



            <table width="100%" class="table responsive-table">
            <thead>
                        <tr>


                            <th> ID </th>
                            <th> Dark store name</th>
                            <th> Mobile number </th>
                            <th> Creation date</th>
                            <th> City</th>

                        </tr>
                    </thead>
                <tbody>



                    {currentTableData.map(data => {
                        return (
                            <tr>
                            <td class="bold">{data.id}</td>
                            <td class="bold"><a href="#">{data.name}</a></td>
                            <td>{data.contactNumber}</td>
                            <td>{
                              helperapi.formatdate(String(data.createdOn))
                                    }</td>
                            <td>{data.cityName}</td>
                        </tr>

                        );
                    })}

                </tbody>
            </table>

            {isLoading == 0 ? <h1 align='center'>Loading..</h1> : null}



            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={props.total}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
            />
        </div>


    );
}

export default DarkstoreList;
