
import React, { useState, useEffect } from 'react';


function Maindash(param) {

    return (
      
        <div class="counts col-12-xs">
          
        <div class="boxs" >
            <img src="img/counts-1.png" alt=""/>
            <div>{param.t("Opened")} <h3>{param.open}</h3></div>
        </div>
        <div class="boxs">
            <img src="img/counts-2.png" alt=""/>
            <div>{param.t("Submitted")} <h3>{param.submitted}</h3></div>
        </div>
        <div class="boxs">
            <img src="img/counts-3.png" alt=""/>
            <div>{param.t("Completed")}  <h3>{param.completed}</h3></div>
        </div>
        <div class="boxs">
            <img src="img/counts-4.png" alt=""/>
            <div>{param.t("Cancelled")}  <h3>{param.cancelled}</h3></div>
        </div>
     </div>
    );
  }
  
  export default Maindash;
  