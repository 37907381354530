import React, { useState, useEffect } from 'react';
import List from '../../component/branch/branchlist';
import api from '../../service/api';

import { useNavigate } from 'react-router-dom';
import { useDashboardStore } from '../../store/useDashboardStore';

import { useTranslation } from 'react-i18next';

function Branchnew() {

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const [branchName, setBranchName] = useState();
    const [branchNameArabic, setBranchNameArabic] = useState();
    const [enBName, setBEnName] = useState();
    const [enName, setEnName] = useState();
    const [arName, setArName] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [passwordRepeat, setPasswordRepeat] = useState();
    const [message, setMessage] = useState();
    const [bc, setBC] = useState();
    const [qoyodCustId, setQoyodCustId] = useState();

    const [location, setLocation] = useState();
    const [mobile, setMobile] = useState();
    const [whatsapp, setWhatsapp] = useState();
    const [status, setStatus] = useState(1);
    const [description, setDescription] = useState();
    const [address, setAddress] = useState();
    const [addressLink, setAddressLink] = useState();
    const [customer, setCustomer] = useState([]);

    const { id, businessCustomers, getBusinessCustomers } = useDashboardStore((state) => ({
        id: state.id,
        businessCustomers: state.businessCustomers,
        getBusinessCustomers: state.getBusinessCustomers,
    }));

    const handleSubmit = async e => {
        e.preventDefault();

        try {
            const data = {
                branchName: branchName,
                branchNameArabic: branchNameArabic,
                ArName: arName,
                EnName: enName,
                Email: email,
                Password: password,
                bc: bc,
                Mobile: mobile,
                Whatsapp: whatsapp,
                ActiveStatus: status,
                Address: address,
                AddressLink: addressLink,
                Description: description,
                QoyodCustId: qoyodCustId
            };
            api.login('POST', '/general/signup', data)
                .then((response) => {
                    console.log(response.data)
                    if (response.data.success == true) {
                        setMessage(response.data.message)
                        alert("Branch Created Successfuly. ")
                        navigate('/branches');
                    } else {

                        alert("Duplicate Entry. Please try with different email")
                    }
                })
                .catch((error) => {
                    console.log('fetching-1');
                })
        } catch (error) {
            console.log('fetching-2');
        }

    }

    const navigatetologin = () => {
        navigate('/login');
    };

    useEffect(() => {
        if (businessCustomers && businessCustomers.data && businessCustomers.data.length > 0) {
            //nothing
        } else {
            getBusinessCustomers();
        }
    }, [getBusinessCustomers])

    useEffect(() => {
        const fetchList = async () => {
            try {
                api.generic('GET', '/general/customers')
                    .then((response) => {
                        console.log(response.data);
                        setCustomer(response.data);
                    })
                    .catch((error) => {
                        // setIsError(1);
                    })
            } catch (error) {
                //setIsError(1);
            }
        };
        // fetchList();
    }, []);

    return (
        <>
            <h1>{t("NewBranch")}</h1>
            <div class="product col-xs-12">
                <div class="boxs col-xs-12">
                    <h5> <span>{t("BranchInfo")}</span></h5>
                    <div class="form">
                        <div class="form-add col-xs-12">
                            <form onSubmit={handleSubmit} style={{ "text-align": "left" }}>
                                <div class="field col-xs-12 col-md-6">
                                    <label>{t("BranchNameinEnglish")}</label>
                                    <input type="text" required name="" class="input" placeholder="branch Name in English" onChange={e => setBranchName(e.target.value)} />
                                </div>

                                <div class="field col-xs-12 col-md-6">
                                    <label>{t("BranchnameinArabic")}</label>
                                    <input type="text" required name="" class="input" placeholder="اسم ألفرع باللغه العربيه" onChange={e => setBranchNameArabic(e.target.value)} />
                                </div>

                                <div class="field col-xs-12 col-md-12">
                                    <label>{t("BranchManagerEmail")}</label>
                                    <input type="mail" name="" required class="input" placeholder="Enter  email" onChange={e => setEmail(e.target.value)} />
                                </div>

                                <div class="field col-xs-12 col-md-6">
                                    <label>{t("BusinessCustomer")}</label>
                                    <select name="businesscustomer" required id="businesscustomer" class="input" onChange={e => setBC(e.target.value)}>
                                        <option value=""  >Select</option>
                                        {businessCustomers?.data?.map(data => {
                                            return (
                                                <option value={data.Id}>{data.EnName}</option>
                                            );
                                        })}
                                    </select>
                                </div>

                                {/* <div class="field col-xs-12 col-md-6">
                                    <label>{t("QoyodCustomerMap")}</label>
                                    <select name="QoyodCustId" required id="QoyodCustId" class="input" onChange={e => setQoyodCustId(e.target.value)}>
                                        <option value=""  >Select</option>
                                        {customer?.customers?.map(data => {
                                            return (
                                                <option value={data.id}>{data.name}</option>
                                            );
                                        })}
                                    </select>
                                </div> */}

                                <div class="field col-xs-12 col-md-6">
                                    <label>{t("ManagerNameInEnglish")}</label>
                                    <input type="text" class="input" placeholder="Manager Name in English" onChange={e => setEnName(e.target.value)} />
                                </div>

                                <div class="field col-xs-12 col-md-6">
                                    <label>{t("ManagerNameInArabic")}</label>
                                    <input type="text" class="input" placeholder="Manager Name in Arabic" onChange={e => setArName(e.target.value)} />
                                </div>

                                <div class="field col-xs-12 col-md-6">
                                    <label>{t("Password")}</label>
                                    <input type="password" name="" required class="input" placeholder="Enter Password" onChange={e => setPassword(e.target.value)} />
                                </div>

                                <div class="field col-xs-12 col-md-6">
                                    <label>{t("RePassword")}</label>
                                    <input type="password" name="" required class="input" placeholder="Enter Password" onChange={e => setPasswordRepeat(e.target.value)} />
                                </div>

                                <div class="field col-xs-12 col-md-6">
                                    <label>{t("WhatsAppNumber")}</label>
                                    <input type="text" class="input" placeholder="WhatsApp" onChange={e => setWhatsapp(e.target.value)} />
                                </div>

                                <div class="field col-xs-12 col-md-6">
                                    <label>{t("MobileNumber")}</label>
                                    <input type="text" class="input" placeholder="Mobile number" onChange={e => setMobile(e.target.value)} />
                                </div>
                                <div class="field col-xs-12 col-md-6">
                                    <label>{t("Address")}</label>
                                    <input type="text" class="input" placeholder="Address" onChange={e => setAddress(e.target.value)} />
                                </div>
                                <div class="field col-xs-12 col-md-6">
                                    <label>{t("AddressLink")}</label>
                                    <input type="text" class="input" placeholder="AddressLink" onChange={e => setAddressLink(e.target.value)} />
                                </div>
                                <div class="field col-xs-12 ">
                                    <label>{t("Description")}</label>
                                    <input type="text" class="input" placeholder="Description" onChange={e => setDescription(e.target.value)} />
                                </div>
                                <div class="field col-xs-12 col-md-6">
                                    <label>{t("Status")}</label>
                                    <select name="status" id="status" onChange={e => setStatus(e.target.value)} >
                                        <option value="1">Approved</option>
                                        <option value="0">Denied</option>
                                    </select>
                                </div>

                                <hr />

                                {
                                    /*
                                  <h5> <span>Files</span></h5>
              
                                  <div class="field col-xs-12 col-md-5"><i class="plus"></i>   <b>business logo</b> | Untitled file.PDF</div>
                                  <div class="field col-xs-12 col-md-7"><i class="plus"></i>   <b>Commercial Registration</b> | Untitled file.DOCX </div>
                                  <div class="field col-xs-12 col-md-5"><i class="plus"></i>   <b>Tax file</b> | Untitled Image.PNG </div>
                                  */
                                }

                                <div class="field col-xs-6 ">
                                </div>

                                <div class="field col-xs-6 ">
                                    <input type="submit" value={t("CreateBranch")} class="submit" />
                                </div>


                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );

}

export default Branchnew;

