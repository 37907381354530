
import React, { useState, useMemo, useEffect } from 'react';
import Pagination from '../common/pagination';
import data from '../../data/mock-data.json';
import { useTranslation } from 'react-i18next';
function Branchuserlist(props) {
    const { t, i18n } = useTranslation();
    
    let PageSize = props.pagesize;

    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(0);
    const [isData, setIsData] = useState(0);



    const currentTableData = useMemo(() => {
        console.log("worked")

        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;

        setIsData(isData + 1);
        if (props.isError == 1) {
            setIsLoading(1);
        }

        if (isData >= 1) {
            setIsLoading(1);
        }

        return props.list.data ? props.list.data.slice(firstPageIndex, lastPageIndex) : [];


    }, [currentPage, props]);

    return (
        <div className="boxs col-xs-12">
        <h5> 
                    <div class="filters-top col-xs-12 col-md-6">
                    </div>
                    
                    <div class="filters-top stores-top col-xs-12 col-md-6">
                    </div>

                </h5>



            <table width="100%" class="table responsive-table">
            <thead> <tr>
                            <th> ID </th>
                            <th> {t("Name")}</th>
                            <th> {t("Email")}</th>
                            <th> {t("MobileNumber")}</th>
                            <th> {t("WhatsAppNumber")}</th>
                            <th> {t("CreationDate")}</th>
                        </tr>
                    </thead>
                <tbody>



                    {currentTableData.map(data => {
                        return (
                            <tr>
                            <td class="bold">00{data.Id}</td>
                            <td class="bold">{data.EnFullName}</td>
                            <td>{data.Email}</td>
                            <td>{data.PhoneNumber}</td>
                            <td>{data.WhatsAppNumber}</td>
                            <td>
                               
                            {new Intl.DateTimeFormat('en-GB', { 
                month: 'long', 
                day: '2-digit',
                year: 'numeric', 
            }).format(new Date(data.CreatedOn))}

                            </td>
                        </tr>

                        );
                    })}

                </tbody>
            </table>

            {isLoading == 0 ? <h1 align='center'>Loading..</h1> : null}



            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={props.total}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
            />
        </div>


    );
}

export default Branchuserlist;
