
import { create } from 'zustand';
import api from '../service/api';

export  const useDashboardStore = create((set) => ({
  id: -1,
  deliveryType: [],
  businessCustomers:[],
  branch:[],
  bcCategory:[],
  locations:[],
  orders:[],
  EnFullName: "",
  ArFullName: "",
  type: "NA",
  histories: ['one', 'two', 'three'],
  neworderinitdata: {},
  orderproducts: [],
  newrfqinit: {},
  rfqproducts: [],
  updateid: (idnew) => set(() => ({ id: idnew })),
  setEnFullName: (newEnFullName) => set(() => ({ EnFullName: newEnFullName })),
  setArFullName: (newArFullName) => set(() => ({ ArFullName: newArFullName })),
  updateneworderinitdata: (neworderinitdatanew) => set(() => ({ neworderinitdata: neworderinitdatanew })),
  updatetype: (typenew) => set(() => ({ type: typenew })),
  increment: () => set(state => ({ id: state.id + 1 })),
  reset: () => set({ id: -1 , type: "NA"}),
  addHistory: (history) => {
    set(state => ({
        histories: [...state.histories, history]
    }));
   },
   getDeliveryType: async () => {
          await api.generic('GET','/general/deliverytype')               
         .then((response)=>{
             set({ deliveryType:  response.data })
         })
         .catch((error) => {
         })
         .finally(() => {
         });
   },
   getBCCategory: async () => {
          await api.generic('GET','/general/bccategory')               
         .then((response)=>{
             set({ bcCategory:  response.data })
         })
         .catch((error) => {
         })
         .finally(() => {
         });
   },
   getBusinessCustomers: async () => {
          await api.generic('GET','/general/businesscustomersbybranch')               
         .then((response)=>{
             set({ businessCustomers:  response.data })
         })
         .catch((error) => {
         })
         .finally(() => {
         });
   },
   getBranch: async () => {
          await api.generic('GET','/general/branch')               
         .then((response)=>{
             set({ branch:  response.data })
         })
         .catch((error) => {
         })
         .finally(() => {
         });
   },
   getLocations: async () => {
          await api.generic('GET','/general/locations')               
         .then((response)=>{
             set({ locations:  response.data })
         })
         .catch((error) => {
         })
         .finally(() => {
         });
   },
   getordersbybranch: async () => {
          await api.generic('GET','/general/ordersbybranch')               
         .then((response)=>{
             set({ orders:  response.data })
         })
         .catch((error) => {
         })
         .finally(() => {
         });
   }/*,
   addOrderProducts: (productid) => {
    set((state) => ({
      orderproducts: [ ...state.orderproducts, productid],
    }));
  }*/
  ,
   addOrderProducts: (obj) => {
    set((state) => ({
      orderproducts: [ ...state.orderproducts, {productid:obj.ProductId,sku:obj.sku,qty:obj.qty}],
    }));
  },

  removeOrderProducts: (id) => {
    set((state) => ({
      orderproducts: state.orderproducts.filter((productid) => productid !== id),
    }));
  },
  reset: () => set({ 
    neworderinitdata: {},
    orderproducts: []
  }),
  resetrfq: () => set({ 
    newrfqinit: {},
    rfqproducts: []
  }),
  addRfqProducts: (obj) => {
    set((state) => ({
      rfqproducts: [ ...state.rfqproducts, {productid:obj.ProductId,sku:obj.sku,qty:obj.qty,price:obj.price}],
    }));
  },

  removeRfqProducts: (id) => {
    set((state) => ({
      rfqproducts: state.rfqproducts.filter((productid) => productid !== id),
    }));
  },


}));

