import {Routes, Route, useNavigate} from 'react-router-dom';
import useState from 'react-usestateref' 
import React, {  useEffect } from 'react';
import api from '../../service/api';
import Nofavourite from '../../component/favourite/nofavourite';
import List from '../../component/favourite/branchfavouritelist';

import { useTranslation } from 'react-i18next';

import FCard from '../../component/favourite/favouritecard';

import { useParams } from 'react-router';

import {useLocation} from 'react-router-dom';


import { useProductStore } from '../../store/useProductStore';

import { Tab } from '@headlessui/react'

import { RESOURCE_URL } from "../../config/datasource";


function BranchFavouriteindividual(props) {
    const { t, i18n } = useTranslation();
    
  const [isError, setIsError] = useState(0);
  const [isLoading, setIsLoading] = useState(0);

  const navigate = useNavigate();


  const location = useLocation();
  const { itemid } = useParams();

  
const [list, setList] = useState([]);
const [listproduct, setListProduct,listproductRef] = useState([]);
const [total, setTotal] = useState(0);
const [rfqprocesscode,setRfqprocesscode] = useState('')
const [deliveryDays,setDeliveryDays,deliveryDaysRef] = useState(0)
const [deliveryCharge,setDeliveryCharge,deliveryChargeRef] = useState(0)

const [proPrice, setProPrice,proPriceRef] = useState([]);  


const [checked, setChecked] = useState(false); 




const placeholderImage =
'img/pro1.png'

const onImageError = (e) => {
    e.target.src = placeholderImage
    }


    function handleChange(e) {
       setChecked(e.target.checked);
    }



const submittobranch = (e) => {
    if(deliveryDaysRef.current<=0 || deliveryChargeRef.current<=0) {
        
    alert("Delivery details not filled")
    return ;
    }
    alert("Submit to Branch for approval or rejection")
    const detail=[]

  console.log(listproduct)  
    
  listproductRef.current.map((data, idx) => 
       {

        var objPrice=[]
  console.log("loop " + idx  + ' ' + data.sku)
   objPrice= proPriceRef.current.filter(a => a.sku == data.sku)
   var price=objPrice[0]?.price?objPrice[0].price:data.UnitPrice;
   console.log(price)
   detail.push({sku:data.sku,ProductId: data.ProductId, BranchUserId:1, UnitPrice:price,Id: data.Id})

       });
///console.log(detail)


const data =  {
    
    Id:itemid,
    deliveryDays:deliveryDaysRef.current,
    deliveryCharge:deliveryChargeRef.current,
    BranchFavouriteListProducts: detail
  };

try { 
    api.generic('POST','/general/rfqs/addupdatestatus',data)             
    .then((response)=>{
        navigate('/favourites',{state:{load:1}});
    })
    .catch((error) => {
      console.log(error)
        alert("Technical issue. Please Try Again");
    })
} catch (error) {
  console.log(error)
    alert("Technical issue. Please Try Again");
}



    //navigate('/favourites');
  };

  
useEffect(() => {
    console.log("log in useeffect")
    console.log(proPrice)

    

  }, [proPrice])


const showfavourites = () => {
    navigate('/favourites');
}

  
const navigatetodetail = (Id) => {

    navigate(`/productdetail/${Id}`, { state: { id: Id } });
};


  const {products, productcategory, getProducts, getProductCategory} = useProductStore((state) => ({
    products: state.products,
    productcategory: state.productcategory,
    getProductCategory: state.getProductCategory,
    getProducts: state.getProducts,
  }));


  

const handlePriceChange = (id,sku, e) => {
    var tempPrice=[];
  console.log(id)
    tempPrice=proPrice.filter(a => a.ProductId !== id)
    setProPrice([
      ...tempPrice,
      {sku:sku,ProductId: id, BranchUserId:1 , price:parseFloat(e.target.value)}
    ]);

    //console.log("Price Change")
    //console.log(proPrice)


    
  
  }


  useEffect(() => {
    if (products && products.products && products.products.length > 0) {
        //nothing 
        } else {
            console.log('product called')
            getProducts();
        }

    if (productcategory && productcategory.categories && productcategory.categories.length > 0) {
        //nothing
    } else {
        console.log("category called")
        getProductCategory();
    }
  }, [getProductCategory, getProducts])

useEffect(() => {
   
   
    
    const fetchList = async () => {
        try {
            api.generic('GET','/general/rfqs/' + itemid)              
            .then((response)=>{
                
                console.log("list")
                console.log(response.data);
                setList(response.data);
            })
            .catch((error) => {
                setIsError(1);
            })
        } catch (error) {
            setIsError(1);
        }
    };


    fetchList();


    const fetchListProduct = async () => {
        try {
            //api.generic('GET','/general/branchfavouritelist/products/' + itemid)  
            api.generic('GET','/general/rfqs/products/' + itemid)                
            .then((response)=>{
                
                console.log(response.data);
                setListProduct(response.data);
                setTotal(response.data.length);
            })
            .catch((error) => {
                setIsError(1);
            })
        } catch (error) {
            setIsError(1);
        }
    };


    fetchListProduct();


  setIsLoading(1)




  }, [location.state])
  

 
useEffect(() => {

    let rfqdata
    if(list.length>0) {
    if (list[0].Status == "Pending Submission") {
      rfqdata = <h5 style={{color:"red"}}>{t("WaitingForQuoteSubmissioByBranch")}</h5>;
  } else if(list[0].Status =="Quote Submitted") {
      rfqdata = <><h5 style={{color:"red"}}>Submit to Branch after price update</h5><button onClick={submittobranch} class="add-product" style={{margin:"5px"}}> Submit to Branch</button></>;
  }
  else if(list[0].Status == "Submitted") {
      rfqdata = <h5 style={{color:"red"}}>Waiting for Branch Approval</h5>;
  }
  else if(list[0].Status == "Approved") {
      rfqdata = <h5 style={{color:"green"}}>Approved by Branch - To be converted into order</h5>;
  }
  else if(list[0].Status == "Rejected") {
      rfqdata = <h5 style={{color:"blue"}}>Rejected by Branch</h5>;
  }
  setRfqprocesscode(rfqdata)
}
}, [list])

  return ( 
    <>
    {isLoading == 0 ? "Loading..." : "" }

  
    <>
    <h1>{t("FavListsDetailfor")} {list[0]?.Title}
      
<button onClick={showfavourites} style={{margin:"5px"}} class="add-product">{t("FavouriteList")}</button> 
{rfqprocesscode}
     </h1>

     <div class="boxs rfq-details col-xs-12">
                <h3 class="col-xs-12">{t("RFQDetails")}</h3>
                <div class="i col-xs-12 col-md-3"><img src="img/users.png" alt=""/> {t("Branch")} : {list[0]?.BranchName}</div>
                <div class="i col-xs-12 col-md-3"><img src="img/fast.png" alt=""/> {t("DeliveryType")}: {list[0]?.bcNameq}</div>
             
                <div class="i col-xs-12 col-md-3"><img src="img/map.png" alt=""/> {t("DeliveryAddress")}: {list[0]?.Address} </div>
                <div class="i col-xs-12 col-md-3"><img src="img/map.png" alt=""/>  <a href={list[0]?.AddressLink} target="about:blank">{list[0]?.AddressLink}</a></div>

                <div class="form-add col-xs-12">

                   

                    <div class="field col-xs-12 col-md-4">
                        <label>{t("ExpectedDeliveryDays")}</label>

                        {list[0]?.Status == "Pending Submission" ||  list[0]?.Status == "Quote Submitted" ? ( 
                        <input required type="number"   class="input" placeholder="expected delivery days" onChange={(e) => setDeliveryDays(e.target.value)}/>) :
                               (
                             <> <input required type="number"  value={list[0]?.ExpDeliveryDay}  class="input" placeholder="expected delivery days" onChange={(e) => setDeliveryDays(e.target.value)}/>  </>   
                                ) }
                                
                    </div>
                    
                    <div class="field col-xs-12 col-md-4">
                        <label>{t("DeliveryCharges")}</label>

                        {list[0]?.Status == "Pending Submission" ||  list[0]?.Status == "Quote Submitted" ? ( 
                        <input required type="number" class="input"  value={deliveryCharge} placeholder={list[0]?.DeliveryCharges} onChange={(e) => setDeliveryCharge(e.target.value)}/>) :
                               (
                             <><input required type="number" class="input"  value={list[0]?.DeliveryCharges}  placeholder={list[0]?.DeliveryCharges} onChange={(e) => setDeliveryCharge(e.target.value)}/>  </>   
                                ) }

                        
                    </div>
                </div> 

            </div>
  


            <div class="boxs rfq-details col-xs-12">
               
                <ul class="nav nav-tabs" role="tablist">
                    <li role="presentation" class="active"><a href="#home" aria-controls="home" role="tab" data-toggle="tab"><img src="img/products.png" alt=""/> {t("Product")}</a></li>
                    <li role="presentation"><a href="#profile" aria-controls="profile" role="tab" data-toggle="tab"><img src="img/summary.png" alt=""/> {t("Summary")}</a></li>
                </ul>
            </div>



            <div class="product col-xs-12">
            <div class="tab-content">
                <div role="tabpanel" class="tab-pane active" id="home">

                <input value = "test" type = "checkbox"  label="Show List" onChange = {handleChange} /> {t("ShowList")}
         <br></br>


         {checked ? (
           <div class="boxs col-xs-12"> 
           <table width="100%" class="table responsive-table">
                                       <thead>
                                           <tr>
                                               <th>line item </th>
                                             <th>Image</th>
                                               <th> SKU </th>
                                               <th> Product </th>
                                              
                                               <th> Quantity </th>
                                            {list[0]?.Status == "Pending Submission" ||  list[0]?.Status == "Quote Submitted" ? (
                                              <></> ) : (<><th> Retail Price (SAR) </th><th> Updated Price (SAR) </th> <th> VAT (15%) </th> <th> Total amount (inc VAT)</th></>)
                                            }
                                               
                                               <th> </th>
                                           </tr>
                                       </thead>
                                       <tbody>
           
                                                 {listproduct?.map((data, idx) => (
           
                                                     products?.products?.filter(value => value.sku === data.sku).map(obj =>
                                                         <tr>
                                                           
                                              <td class="bold">{++idx}</td>
                                                           <td> <img
                           src={RESOURCE_URL +  '/product/' + data.ProductId + '_img.png' ? RESOURCE_URL +  '/product/' + data.ProductId + '_img.png' : placeholderImage}
                           alt=""
                           onError={onImageError}
                         />
           </td>  <td class="bold">{obj.sku}</td>
                                               <td class="bold">{obj.name_en}</td>
                                             
                                               <td> {data.Quantity}</td>
                                               {list[0]?.Status == "Pending Submission" ||  list[0]?.Status == "Quote Submitted" ? (
                                              <></> ) : (<> <td> SAR {obj.selling_price} </td><td> {data.UnitPrice} </td><td>SAR  {(((data.UnitPrice*data.Quantity)/100)*15).toFixed(2)}</td> <td class="bold">SAR { Number(data.UnitPrice  *data.Quantity ) + Number ((((data.UnitPrice*data.Quantity)/100)*15).toFixed(2) )} </td></>)
                                            }
           
                                               
                                               <td><a onClick={() => navigatetodetail(obj.id)}><i class="fa fa-eye"></i></a></td>
                                               </tr>
                                                     )
           
                                                 ))}
           
                                       
                                          
                                       </tbody>
                                   </table>
           
                                   </div>
         ) : (
            <div class="products col-xs-12">
            {listproduct?.map((data, idx) => (
                <FCard key={idx} productid={data.ProductId} qty= {data.Quantity} param={products?.products?.filter(value => value.sku === data.sku)}/>

                ))}


</div>
         )}



          
                </div>
                <div role="tabpanel" class="tab-pane" id="profile">  

                 
                <div class="boxs col-xs-12"> 
                {list[0]?.Status == "Pending Submission" ||  list[0]?.Status == "Quote Submitted" ? ( 
                        <div class="total-vat col-xs-12">
                            <div>
                               
                            </div>
                            <div></div>
                        </div>
                ) : (
                    <div class="total-vat col-xs-12">
                    <div>
                        <span>Total amount Excl VAT:  <strong>SAR {list[0]?.TotalRFQPrice}</strong></span>
                        <span>Delivery charges: <strong>SAR {list[0]?.DeliveryCharges}</strong></span>
                        <span>VAT AMT: <strong>{list[0]?.TotalVATAmount}</strong></span>
                    </div>
                    <div><span class="grand">Grand Total: <strong>{list[0]?.TotalAmount}</strong></span></div>
                </div>
                )}

                        <table width="100%" class="table responsive-table">
                            <thead>
                                <tr>
                                    <th>{t("lineitem")} </th>
                                    <th> {t("Products")} </th>
                                    <th> {t("TotalAmount")}</th>
                                    <th> {t("Quantity")} </th>
                                    <th> {t("RetailPriceSAR")} </th>
                                    <th> {t("UpdatedPriceSAR")} </th>
                                    <th> {t("VAT15")} </th>
                                    <th> </th>
                                </tr>
                            </thead>
                            <tbody>

                                      {listproduct?.map((data, idx) => (

                                          products?.products?.filter(value => value.sku === data.sku).map(obj =>
                                              <tr>
                                   <td class="bold">00{obj.id}</td>
                                    <td class="bold">{obj.name_en}</td>
                                    <td class="bold">SAR 
                                    { list[0]?.Status == "Quote Submitted" ? ( 
                    <>{proPriceRef.current.filter(a => a.sku == data.sku)[0]?.price?(parseFloat((parseFloat((proPriceRef.current.filter(a => a.sku == data.sku)[0].price/100)*15)  +  parseFloat(proPriceRef.current.filter(a => a.sku == data.sku)[0].price ))*data.Quantity).toFixed(2) ) :data.TotalPrice}</>
                ) : (
<>{data.TotalPrice}</> 
                ) }

                                   </td>
                                    <td> {data.Quantity}</td>
                                    <td> SAR {obj.selling_price} </td>
                                    <td> 
<input type="number"   
class="form-control text-center" value={proPrice.filter(a => a.ProductId == data.ProductId).length!=0?proPrice.filter(a => a.ProductId == data.ProductId)[0].price:data.UnitPrice}  onChange={(e) => handlePriceChange(data.ProductId, data.sku, e)}  />

                                    </td>
                                    <td>  
                                    
                                    { list[0]?.Status == "Quote Submitted" ? ( 
                    <>{proPriceRef.current.filter(a => a.sku == data.sku)[0]?.price?parseFloat((proPriceRef.current.filter(a => a.sku == data.sku)[0].price/100)*15).toFixed(2):data.VATAmount}</>
                ) : (
<>{data.VATAmount}</> 
                ) }
                                    
                                    </td>
                                    <td><a onClick={() => navigatetodetail(obj.id)}><i class="fa fa-eye"></i></a></td>
                                    </tr>
                                          )

                                      ))}

                            
                               
                            </tbody>
                        </table>

                    </div>
                </div>
        </div>
        </div>




        
    </>
  </>
); 
}

export default BranchFavouriteindividual; 