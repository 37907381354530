import React, { useState, useEffect } from 'react';
import List from '../../component/finance/financelist';
import api from '../../service/api';
import {Routes, Route, useNavigate} from 'react-router-dom';


import { useTranslation } from "react-i18next";

function Finances() { 

    const { i18n,t } = useTranslation();
    const [list, setList] = useState([]);
    const [listCategory, setListCategory] = useState([]);
    const [listOther, setListOther] = useState([]);
    const [total, setTotal] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [isError, setIsError] = useState(0);
        
    
    const [open, setOpen] = useState(0);
    const [submitted, setSubmitted] = useState(0);
    const [completed, setCompleted] = useState(0);
    const [cancelled, setCancelled] = useState(0);
    const [paid, setPaid] = useState(0);
    const [unpaid, setUnpaid] = useState(0);

    const navigate = useNavigate();

    
    const navigatetoneworder = () => {
        navigate('/ordernew');
    }

    useEffect(() => {
            

        const fetchList = async () => {
            try {
                await api.generic('GET','/general/ordersforinvoice')             
                .then((response)=>{
                    
                    console.log(response.data);
                    setList(response.data);
                    setTotalData(response.data.data.length);
                  setTotal(parseFloat(response.data.data.map(item => parseFloat(item.TotalAmount)).reduce((a, b) => a + b))?.toFixed(2));  
                    setSubmitted(response.data.data.filter(value => value.Status === 'Submitted').length);
                    
                //   

                    setCompleted(response.data.data.filter(value => value.Status === 'Completed').length);
                    setCancelled(response.data.data.filter(value => value.Status === 'Cancelled').length);
                    
                   console.log("1")
                    setPaid(response.data.data.filter(value => value.PaymentStatus == 'Paid').length);
                    setUnpaid(response.data.data.filter(value => value.PaymentStatus == 'Unpaid').length);
//console.log("paid" + response.data.data.filter(value => value.PaymentStatus == 'Paid').length)

setOpen(response.data.data.filter(value => value.PaymentStatus == 'Unpaid').map(item => parseFloat(item.TotalAmount)).reduce((a, b) => a + b));

//console.log(response.data.data.filter(value => value.PaymentStatus === 'Paid'))
//console.log("paid")
                    
                })
                .catch((error) => {
                    setIsError(1);
                })
            } catch (error) {
                setIsError(1);
            }
        };


        fetchList();



        
    }, []);


    return ( 
       <>
        <h1>{t("Insights")}</h1>

<div class="counts col-12-xs">
   <div class="boxs insights">
       <img src="img/counts-1.png" alt=""/>
       <div>{t("TotalInvoiceValue")}</div>
       <h2>{total}</h2>
       <div class="foot">
       </div>
   </div>
   <div class="boxs insights">
       <img src="img/counts-5.png" alt=""/>
       <div>{t("OpenInvoiceValue")}</div>
       <h2>{open}</h2>
       <div class="foot">
       </div>
   </div>
   <div class="boxs insights">
       <img src="img/counts-3.png" alt=""/>
       <div>{t("PaidInvoices")}</div>
       <h2>{paid}</h2>
       <div class="foot">
       </div>
   </div>
   <div class="boxs insights">
       <img src="img/counts-4.png" alt=""/>
       <div>{t("UnpaidInvoices")}</div>
       <h2>{unpaid}</h2>
       <div class="foot">
       </div>
   </div>
</div>


     <div class="product col-xs-12">
        
        
     <List list={list} total={totalData} pagesize={10} isError={isError} />
     </div>
       </>
    ); 
} 
export default Finances; 