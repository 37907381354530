
import React, { useState, useMemo, useEffect } from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';
import { RESOURCE_URL } from "../../config/datasource";


   

function OrderProductCard(props) {
   
const placeholderImage =
'img/pro1.png'


const onImageError = (e) => {
    e.target.src = placeholderImage
    }


    return (
        <div class="item-products">
            {props.param ? (
                <>

        <img
              src={RESOURCE_URL +  '/product/' + props.productid + '_img.png' ? RESOURCE_URL +  '/product/' + props.productid  + '_img.png' : placeholderImage}
              alt=""
              onError={onImageError}
            />

        <h4>SAR {props.price} <span>( Per unit )</span></h4>
        <p>{props.name}</p>
        <div class="uom"></div>
        <h3>SKU ({props.sku})</h3>
        <div class="quantity">
            Quantity <strong>{props.qty}</strong>
        </div>
        <div class="covers">
        </div></>
) : ('')
}    </div>

    );
}

export default OrderProductCard;