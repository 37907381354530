import React, { useState, useEffect } from 'react';
import List from '../../component/branch/branchlistbc';
import api from '../../service/api';

import { useParams } from 'react-router';

import { useTranslation } from 'react-i18next';

import { useDashboardStore } from '../../store/useDashboardStore';
import { useNavigate } from 'react-router-dom';

import {useRef} from 'react';
import { RESOURCE_URL } from "../../config/datasource";

function BusinessCustomersBranches() { 

    const { t, i18n } = useTranslation();

    const [list, setList] = useState([]);
    const [listCategory, setListCategory] = useState([]);
    const [listOther, setListOther] = useState([]);
    const [total, setTotal] = useState(0);
    const [isError, setIsError] = useState(0);
        
  const { itemid } = useParams();
  

  
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  
  const inputRef = useRef(null);
  const [dataId, setDataId] = useState(0);
    
   const [type, setType] = useState('');
   const fallbackSrc = RESOURCE_URL + "/images/1_logo.png";
   const [isLogo, setIsLogo] = useState(0);
   const [isTax, setIsTax] = useState(0);
   const [isComm, setIsComm] = useState(0);


const {id,bcCategory,getBCCategory} = useDashboardStore((state) => ({
  id: state.id,
  bcCategory: state.bcCategory,
  getBCCategory: state.getBCCategory,
}));
  



   
const handleUpload = (Id, type) => {
  // 👇️ open file input box on click of another element
  setDataId(Id)
  setType(type)

  inputRef.current.click();
};


function getExtension(filename) {
  return filename.split('.').pop()
}


const handleFileChange = event => {
  console.log(dataId)
  const fileObj = event.target.files && event.target.files[0];
  if (!fileObj) {
    return;
  }

  console.log('fileObj is', fileObj);

  // 👇️ reset file input
  event.target.value = null;

  // 👇️ is now empty
  console.log(event.target.files);

  // 👇️ can still access file object here
  console.log(fileObj);
  console.log(fileObj.name);
if(type=="logo" && getExtension(fileObj.name.toLowerCase()) != "png") {
alert("File extension should be .png "  + "Currently extension is " + "." + getExtension(fileObj.name.toLowerCase())  )
return false;
}

if(type=="tax" && getExtension(fileObj.name.toLowerCase()) != "pdf") {
alert("File extension should be .pdf "  + "Currently extension is " + "." + getExtension(fileObj.name.toLowerCase())  )
return false;
}


if(type=="comm" && getExtension(fileObj.name.toLowerCase()) != "pdf") {
alert("File extension should be .pdf "  + "Currently extension is " + "." + getExtension(fileObj.name.toLowerCase())  )
return false;
}
    const formData = new FormData();
    formData.append("Id", dataId);
    formData.append("Type", type);
    formData.append("file", fileObj);
    formData.append("name", fileObj.name);

    
  try {
  
api.generic('POST','/general/uploadbranch',formData)             
      .then((response)=>{
          
          console.log(response.data);
          if(response.data.success==true) {
              
              alert("Uploaded successfully")
              window.location.reload(); 
          }
          
      })
      .catch((error) => {
          console.log(error)
      })
  } catch (error) {
  }
  

};

async function exists(url, type) {

  const data =  {
      path:url
    };

  try {
      api.generic('POST','general/fileexist',data)               
      .then((response)=>{
          console.log(response.data)
          console.log(response.data.success)
          if(response.data.success) {
              if(type=='logo') {
              setIsLogo(1)
              }
              if(type=='tax') {
                  setIsTax(1)
                  }

                  if(type=='comm') {
                      setIsComm(1)
                      }

              return true;

          } else {
              
              if(type=='logo') {
              setIsLogo(0) }

              if(type=='tax') {
                  setIsTax(0)
                  }

                  if(type=='comm') {
                      setIsComm(0)
                      }

              return false;

          }
      })
      .catch((error) => {
      
              
          if(type=='logo') {
              setIsLogo(0) }

              if(type=='tax') {
                  setIsTax(0)
                  }

                  if(type=='comm') {
                      setIsComm(0)
                      }

          return false;
      })
  } catch (error) {

              
      if(type=='logo') {
          setIsLogo(0) }

          if(type=='tax') {
              setIsTax(0)
              }

              if(type=='comm') {
                  setIsComm(0)
                  }

      return false;
  }

}


const navigatetomain = () => {

navigate('/businesscustomers');
};


const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };


const handleSubmit = (event) => {
  event.preventDefault();
  
  try {
  const data =  {
     BcId:itemid,
      Id:formData.Id,
      EnName:formData.EnName,
      ArName:formData.ArName,
      BCCategoryId:formData.BCCategoryId,
      MainContactPerson:formData.MainContactPerson,
      ContactPersonMobileNo:formData.ContactPersonMobileNo,
      ContactPersonWhatsappNo:formData.ContactPersonWhatsappNo,
      Notes:formData.Notes,
      ActiveStatus:formData.ActiveStatus
    };




  api.generic('POST','/bc/updatebyid',data)               
  .then((response)=>{
    console.log(response.data)
      const { success, message } = response.data;
     if(success) {
     alert("Saved Successfully")
     } else {
      alert(message)
     }
      
  })
  .catch((error) => {
      console.log('error - from server');
  })
} catch (error) {
  console.log('error - not reachable');
}



};



const onChange=(e)=>{
  let name=e.target.name;
  let value=e.target.value;
  const newValues = {
  ...formData,
  [name]: value
} 
setFormData(newValues)

}

  useEffect(() => {

      const fetchList = async () => {
          try {
              //api.generic('GET','/bc/showbc/' )      
              api.generic('GET','/bc/showbcbyid/' + itemid )           
              .then((response)=>{
                  
                  console.log(response.data);
                 // setList(response.data);
                //  setTotal(response.data.data.length);

                  setFormData({ ...response.data.data[0] });

              })
              .catch((error) => {
                  setIsError(1);
              })
          } catch (error) {
              setIsError(1);
          }
      };


      fetchList();

      

      
  }, []);


  useEffect(() => {
      if (bcCategory && bcCategory.data && bcCategory.data.length > 0) {
          //nothing
      } else {
        getBCCategory();
      }
    }, [getBCCategory])

    

  useEffect(() => {
      exists('/branch/' + formData.Id + '_logo.png','logo')      
      exists('/branch/' + formData.Id + '_tax.pdf','tax')       
      exists('/branch/' + formData.Id + '_comm.pdf','comm')         
  }, [formData]);

    useEffect(() => {
            

        const fetchList = async () => {
            try {
                console.log(itemid)
                api.generic('GET','/general/branch/' + itemid)               
                .then((response)=>{
                    
                  //  console.log(response.data);
                   // console.log(response.data.data.filter(a => a.BCId == itemid));
                    
                    setList(response.data);
                    setTotal(response.data.data.length);
                })
                .catch((error) => {
                    setIsError(1);
                })
            } catch (error) {
                setIsError(1);
            }
        };


        fetchList();



        
    }, []);

    return ( 
      <>

      
<h1>{t("BusinessCustomerProfile")}</h1>


<form onSubmit={handleSubmit}>
<div class="product col-xs-12">
    <div class="boxs col-xs-12"> 
        <h5> <span>{t("GeneralDetails")}</span></h5>

        <div class="form-add col-xs-12">
            <div class="field col-xs-12 col-md-6">
                <label>{t("CustomerNameInEnglish")}</label>
                <input type="text" class="input" name= "EnName" id="EnName" placeholder="Customer Name in English" value={formData.EnName || ''}  onChange={onChange} />
            </div>

            <div class="field col-xs-12 col-md-6">
                <label>{t("CustomerNameInArabic")}</label>
                <input type="text" class="input" name= "ArName" id="ArName" placeholder="اسم العميل باللغه العربيه" value={formData.ArName || ''}  onChange={onChange} />
            </div>
            
            <div class="field col-xs-12 col-md-6">
                <label>{t("CustomerEmail")}</label>
                <input  type="mail" class="input" placeholder="Customer email" value={formData.BCEmail || ''}/>
            </div>
            
            
            <div class="field col-xs-12 col-md-6">
                <label>{t("CustomerCategory")}</label>

                <select required name="BCCategoryId" required id="BCCategoryId" class="input"  onChange={onChange}  value={formData.BCCategoryId || ''}    >
             <option value=""  >Select</option>
             { bcCategory?.data?.map(data => {
                    return(
                    <option value={data.Id}>{data.Name}</option>
                    );
                })}
             </select>

             
            </div>

            
            <div class="field col-xs-12 col-md-6">
                <label>{t("MainPersonName")}</label>
                <input type="text" class="input" placeholder="Main person name" value={formData.MainContactPerson || ''}  name= "MainContactPerson" id="MainContactPerson" onChange={onChange}  />
            </div>

            <div class="field col-xs-12 col-md-6">
                <label>{t("MobileNumber")}</label>
                <input type="text" class="input" placeholder="Mobile number" value={formData.ContactPersonMobileNo || ''}  name= "ContactPersonMobileNo" id="ContactPersonMobileNo" onChange={onChange}  />
            </div>


            <div class="field col-xs-12 col-md-6">
                <label>{t("WhatsAppNumber")}</label>
                <input type="text" class="input" placeholder="WhatsApp" value={formData.ContactPersonWhatsappNo || ''}  name= "ContactPersonWhatsappNo" id="ContactPersonWhatsappNo" onChange={onChange} />
            </div>

            <div class="field col-xs-12 col-md-6">
                <label>{t("Description")}</label>
                <input type="text" class="input" placeholder="Description" value={formData.Notes || ''} name= "Notes" id="Notes" onChange={onChange}  />
            </div>
            <div class="field col-xs-12 col-md-6">
                        <label>{t("Status")}</label>
                        <select required  value={formData.ActiveStatus || '0'}  id="ActiveStatus" name="ActiveStatus" onChange={handleChange}>
                            <option value="">Select</option>
                            <option value="1">Active</option>
                            <option value="0">In-Active</option>
                        </select>
                    </div>

            <hr/>
            <h5> <span>Files (* Upload files)</span></h5>

            <div class="field col-xs-12 col-md-5"><i class="plus"></i>   <b>{t("BusinessLogo")} (png)</b> <a  onClick={() => handleUpload(formData.Id,"logo")}>{t("Upload")}</a> | 
            {
            isLogo  ?
        (<><a target="about:blank" href={RESOURCE_URL +  '/branch/' + formData.Id + '_logo.png'}> {t("Show")}</a></>): (<></>)    
        }
            
</div>
            <div class="field col-xs-12 col-md-7"><i class="plus"></i>   <b>{t("CommercialRegistration")}(pdf) <a  onClick={() => handleUpload(formData.Id,"comm")}>{t("Upload")}</a></b> |   {
            isComm  ?
        (<><a target="about:blank" href={RESOURCE_URL +  '/branch/' + formData.Id + '_comm.pdf'}>{t("Show")}</a></>): (<></>)    
        }</div>
            <div class="field col-xs-12 col-md-5"><i class="plus"></i>   <b>{t("TaxFile")}(pdf)</b><a  onClick={() => handleUpload(formData.Id,"tax")}>{t("Upload")}</a> | {
            isTax  ?
        (<><a target="about:blank" href={RESOURCE_URL +  '/branch/' + formData.Id + '_tax.pdf'}>{t("Show")}</a></>): (<></>)    
        } </div>

            
<input
style={{display: 'none'}}
ref={inputRef}
type="file"
onChange={handleFileChange}
/>


            
        </div>
    </div>
</div>




<div class="product col-xs-12">
    <div class="boxs create-product create-branch col-xs-12">

        <div class="field">
        </div>
        <div>
            <button class="create" type="submit"> {t("Saveinformation")}</button>
            <button class="" onClick={navigatetomain}> {t("Cancel")} </button>
        </div>
    </div>
</div>
</form>

   <h1>{t("ManageBranchForBusinessCustomer")}
           {/* <button  class="add-product"><span>+</span>Add new branch</button> */ }
           
         </h1>

     <div class="product col-xs-12">
        
        
     <List list={list} total={total} pagesize={5} isError={isError} />
     </div>
   </>
    ); 

} 

export default BusinessCustomersBranches; 

