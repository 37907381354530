import React, { useState, useEffect } from 'react';
import List from '../../component/branch/branchlist';
import api from '../../service/api';

import { Routes, Route, useNavigate } from 'react-router-dom';

import { useTranslation } from "react-i18next";

function Branches() {


    const { i18n, t } = useTranslation();

    const [list, setList] = useState([]);
    const [listCategory, setListCategory] = useState([]);
    const [listOther, setListOther] = useState([]);
    const [total, setTotal] = useState(0);
    const [isError, setIsError] = useState(0);



    const navigate = useNavigate();


    const navigatetobranches = () => {
        navigate('/branchnew');
    }


    useEffect(() => {


        const fetchList = async () => {
            try {
                api.generic('GET', '/general/branch')
                    .then((response) => {

                        console.log(response.data);
                        setList(response.data);
                        setTotal(response.data.data.length);
                    })
                    .catch((error) => {
                        setIsError(1);
                    })
            } catch (error) {
                setIsError(1);
            }
        };


        fetchList();


    }, []);

    return (
        <>
            <h1> {t("Branch")}
                <button class="add-product" onClick={navigatetobranches} ><span>+</span>{t("Addnewbranch")}</button>

            </h1>

            <h6>* {t("BranchesCanAlsoBeCreatedFromRegistrationPageOfBranch")}.<a href="https://branch.b2b.sa/signup" target="about:blank">Click Here</a></h6>
            <div class="product col-xs-12">


                <List list={list} total={total} pagesize={10} isError={isError} />
            </div>
        </>
    );

}

export default Branches;

