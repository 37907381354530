import React, { useState } from 'react';
import api from '../../service/api';
import { useNavigate } from 'react-router-dom';


import { useDashboardStore } from '../../store/useDashboardStore';


import { useTranslation } from "react-i18next";


const Login = ({ setToken }) => {
  
  const { i18n,t } = useTranslation();

  const navigate = useNavigate();
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();

    const [message, setMessage] = useState();
    
    const {id,EnFullName,ArFullName,setEnFullName,setArFullName} = useDashboardStore((state) => ({
      id: state.id,
      EnFullName: state.EnFullName,
      ArFullName: state.ArFullName,
      setEnFullName: state.setEnFullName,
      setArFullName: state.setArFullName,
    }));
    

    const navigatetosignup = () => {
      navigate('/signup');
    };
  
    const handleSubmit = async e => {
        e.preventDefault();

       try {

        const credentials =  {
            email:username,
            password:password
          };
          console.log("before call");
        api.login('POST','/general/login',credentials)               
        .then((response)=>{
          console.log(response.data)
            const { accessToken, refreshToken,EnFullName,ArFullName } = response.data;
            if(accessToken) {
              console.log(accessToken);
              setEnFullName(EnFullName)
              setArFullName(ArFullName)
            window.localStorage.setItem("accessToken", accessToken);
            window.localStorage.setItem("refreshToken", refreshToken);
            navigate('/');
            }
            else {
              setMessage("Invalid Credentials")
            //    navigate('/login');

            }
            
        })
        .catch((error) => {
            console.log('fetching-1');
        })
    } catch (error) {
        console.log('fetching-2');
    }

      }


    return ( 
      <section class="login-page">
      <div class="container">
        <div class="row">
          <div class="block col-xs-12 col-md-6"><img src="img/login.svg" alt=""/></div>
          <div class="block col-xs-12 col-md-6">
              <div class="form">
              <form onSubmit={handleSubmit} style={{textAlign:"left"}}>
                      <h2>{t("loginwelcome")}</h2>
                      <h4>{t("logintitle")}</h4>
                      <label>{t("username")}</label>
                      <input type="text" name="" class="input" placeholder="Enter username" onChange={e => setUserName(e.target.value)}/>
                      <label>{t("password")}</label>
                      <input type="password" name="" class="input" placeholder="Enter Password" onChange={e => setPassword(e.target.value)}/>
                      { /*<label><input type="checkbox" class="checkbox"/> remember me</label> */ }
                      <input type="submit" value={t("loginbutton")} class="submit"/>
                  </form>
                  {message}
              </div>
          </div>
       </div>
      </div> 
    </section>

    
    ); 
} 
export default Login; 
