import React, { useState, useEffect } from 'react';
import api from '../../service/api';
import {Routes, Route, useNavigate} from 'react-router-dom';

import { useDashboardStore } from '../../store/useDashboardStore';
import { useTranslation } from 'react-i18next';

function OrderNew() { 
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    const [loading, setloading] = useState(true);
    const [bc, setBC] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [locationId, setLocationId] =  useState(0);
    const [branchId, setBranchId] =  useState('');
    const [address, setAddress] =  useState('');
    const [addressLink, setAddressLink] =  useState('');
    const [branchName, setBranchName] =  useState('');
    const [mobile, setMobile] =  useState('');
    const [email, setEmail] =  useState('');
    
    const [deliveryTypeSelected, setDeliveryTypeSelected] =  useState('');
    const [deliveryCharge, setDeliveryCharge] =  useState(0);
    const [deliveryDays, setdeliveryDays] =  useState(0);
    const [description, setDescription] =  useState('');


    const {id,deliveryType,getDeliveryType,locations,branch,getLocations,businessCustomers,getBusinessCustomers,neworderinitdata,updateneworderinitdata,getBranch, reset} = useDashboardStore((state) => ({
        id: state.id,
        deliveryType: state.deliveryType,
        getDeliveryType: state.getDeliveryType,
        locations: state.locations,
        getLocations: state.getLocations,
        branch: state.branch,
        getBranch: state.getBranch,
        businessCustomers: state.businessCustomers,
        getBusinessCustomers: state.getBusinessCustomers,
        neworderinitdata: state.neworderinitdata,
        updateneworderinitdata: state.updateneworderinitdata,
        reset:state.reset,
      }));


      useEffect(() => {
        if (deliveryType && deliveryType.data && deliveryType.data.length > 0) {
            //nothing
        } else {
            getDeliveryType();
        }
      }, [getDeliveryType])

      
      useEffect(() => {
        if (businessCustomers && businessCustomers.data && businessCustomers.data.length > 0) {
            //nothing
        } else {
            getBusinessCustomers();
        }
      }, [getBusinessCustomers])


      
      useEffect(() => {
        if (locations && locations.data && locations.data.length > 0) {
            //nothing
        } else {
            getLocations();
        }
      }, [getLocations])

      useEffect(() => {
        if (branch && branch.data && branch.data.length > 0) {
            //nothing
        } else {
            getBranch();
        }
      }, [getBranch])

    const navigatetoavailableproductneworder = () => {
        var neworderinit={
            bc: bc,
            address:address,
            addressLink:addressLink,
            deliverytypeid:deliveryTypeSelected,
            deliveryCharge:deliveryCharge,
            deliveryDays:deliveryDays,
            description:description,
            branchId:branchId,
            mobile:mobile,
            email:email,
            branchName:branchName
        }
        if(bc.length==0 || branchId.length==0  || deliveryTypeSelected.length==0 || deliveryCharge<=-1 || deliveryDays<=-1 || deliveryCharge.length==0 ||  deliveryDays.length==0 ) {
            alert("Enter the required fields")

        } else {
        reset()
        updateneworderinitdata(neworderinit)

        navigate('/availableproductsneworder');
        }
    }

    const navigatetoorders= () => {
        navigate('/orders');
    }

    return ( 
      <> <h1> <a class="back" onClick={navigatetoorders}></a> {t("CreateNewOrder")} </h1>
      <div class="step col-xs-12">
         <div class="active"><span>1</span> {t("Deliverydetails")} </div>
         <div><span>2</span> {t("AddProducts")} </div>
         <div><span>3</span> {t("Summary")} </div>
      </div>


     <div class="product col-xs-12">
         <div class="boxs col-xs-12"> 
             <h5> <span>{t("Deliverydetails")}</span></h5>

             <div class="form-add col-xs-12">
                 <div class="field col-xs-12 col-md-4">
                     <label>* BC</label>
                     <select required name="customerid" id="customerid"   onChange={e => setBC(e.target.value)}>
                     <option value="0">Select</option>
                     { businessCustomers.data?.map(data => {
                            return(
                            <option value={data.Id}>{data.EnName}</option>
                            );
                        })}
                     </select>
                 </div>
                 <div class="field col-xs-12 col-md-4">
                     <label>* {t("Branch")}</label>
                     <select required name="branchid" id="branchid" onChange={e => {setBranchName(e.target[e.target.selectedIndex].getAttribute('branchName'));setEmail(e.target[e.target.selectedIndex].getAttribute('email'));setMobile(e.target[e.target.selectedIndex].getAttribute('mobile'));setAddressLink(e.target[e.target.selectedIndex].getAttribute('addresslink'));setAddress(e.target[e.target.selectedIndex].getAttribute('address')); setBranchId(e.target.value)}}>
                         <option value="">Select</option>
                       
                         { branch?.data?.filter(bra => bra.BCId == bc)?.map(data => {
                            return(
                            <option   email={data.Email} mobile={data.ContactPersonMobileNo} branchname={data.EnName} addresslink={data.AddressLink} address={data.Address} value={data.Id}>{data.EnName}</option>
                            );
                        })}
                     </select>
                 </div>
                 <div class="field col-xs-12 col-md-4">
                     <label>{t("DeliveryAddress")}</label>
                     {address} <br/>
                     {addressLink}
                 </div>

                 <div class="field col-xs-12 col-md-4">
                     <label>* {t("DeliveryType")}</label>
                     <select name="deliverytype" id="deliverytype" onChange={e => setDeliveryTypeSelected(e.target.value)}>
                     <option value="">Select</option>
                     { deliveryType.data?.map(data => {
                            return(
                            <option value={data.Id}>{data.Name}</option>
                            );
                        })}
                     </select>
                 </div>
                 
                 
                 <div class="field col-xs-12 col-md-4">
                     <label>* {t("DeliveryCharges")}</label>
                     <input type="text"  value={deliveryCharge} class="input" placeholder="Delivery Charges" onChange={e => setDeliveryCharge(e.target.value)}/>
                 </div>
                 
                 <div class="field col-xs-12 col-md-4">
                     <label>* {t("ExpectedDeliveryDays")}</label>
                     <input type="text"  value={deliveryDays}  class="input" placeholder="Expected Delivery days" onChange={e => setdeliveryDays(e.target.value)}/>
                 </div>

                 {/*
                 <div class="field col-xs-12 col-md-4">
                     <label>Retail Price</label>
                     <input type="text" class="input" placeholder="Retail Price"/>
                 </div>
                    */}
                 <div class="field col-xs-12 col-md-8">
                     <label>{t("Description")}</label>
                     <input type="text" class="input" placeholder="Description" onChange={e => setDescription(e.target.value)}/>
                 </div>

             </div>
         </div>
     </div>



     <div class="product col-xs-12">
         <div class="boxs create-product col-xs-12"> 
             <button  onclick="location.href='Create-new-order-Add-Products.html'"  class="create" onClick={navigatetoavailableproductneworder}>{t("Next")}</button>
             <button class="cancel" onClick={navigatetoorders}>{t("Cancel")}</button>
         </div>
     </div>
   </>
    ); 

} 

export default OrderNew; 