
export default {
    formatdate: (datevalue) => {
        try {
        return new Intl.DateTimeFormat('en-GB', {
            month: 'long',
            day: '2-digit',
            year: 'numeric',
        }).format(new Date(datevalue))
    } catch {
        return datevalue
    }

    }


}

