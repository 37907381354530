import React, { useState, useEffect } from 'react';
import List from '../../component/b2busers/b2buserslist';
import api from '../../service/api';

import { useTranslation } from "react-i18next";

function B2BUsers() { 


    const [list, setList] = useState([]);
    const [listCategory, setListCategory] = useState([]);
    const [listOther, setListOther] = useState([]);
    const [total, setTotal] = useState(0);
    const [isError, setIsError] = useState(0);
    const { i18n,t } = useTranslation();
        
    useEffect(() => {
            

        const fetchList = async () => {
            try {
                api.generic('GET','/general/b2buser')               
                .then((response)=>{
                    
                    console.log(response.data);
                    setList(response.data);
                    setTotal(response.data.data.length);
                })
                .catch((error) => {
                    setIsError(1);
                })
            } catch (error) {
                setIsError(1);
            }
        };


        fetchList();



        
    }, []);

    return ( 
      <>
   <h1>{t("ManageB2BUsers")}
          {/*  <button  class="add-product"><span>+</span>Add Users</button>  */}  
         </h1>

     <div class="product col-xs-12">
        
        
     <List list={list} total={total} pagesize={10} isError={isError} />
     </div>
   </>
    ); 

} 

export default B2BUsers; 