
import React, { useState, useMemo, useEffect } from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';
import Pagination from '../common/pagination';
import data from '../../data/mock-data.json';

import api from '../../service/api';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
function Branchlistbc(props) {
    const { t, i18n } = useTranslation();
    
    let PageSize = props.pagesize;

    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(0);
    const [isData, setIsData] = useState(0);

    const navigate = useNavigate();


    const currentTableData = useMemo(() => {
        console.log("worked")

        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;

        setIsData(isData + 1);
        if (props.isError == 1) {
            setIsLoading(1);
        }

        if (isData >= 1) {
            setIsLoading(1);
        }

      
        return props.list.data ? props.list.data.slice(firstPageIndex, lastPageIndex) : [];

    }, [currentPage, props]);


    

const navigatetodetail = (Id) => {
    navigate(`/branchuser/${Id}`, { state: { itemid: Id } });
};



    const approveBranch= (Id) => {
     
        const data =  {
            Id:Id,
            IsApproved:0
          };
          try { 
            api.generic('POST','/general/changestatus',data)             
            .then((response)=>{
                console.log(response)
                if(response.data.success==true) {
                    window.location.reload(); 
                } else {
                alert("Technical issue. Please Try Again");
                }
                //navigate('/branchfavourites',{state:{load:1}});
            })
            .catch((error) => {
              console.log(error)
                alert("Technical issue. Please Try Again");
            })
        } catch (error) {
          console.log(error)
            alert("Technical issue. Please Try Again");
        }
        
    }


    return (
        <div className="boxs col-xs-12">
        <h5> 
                    <div class="filters-top col-xs-12 col-md-6">
                       {/* <form action="">
                        <input type="text" class="search" placeholder="Search"/>
                        </form> */} 
                    </div>
                    
                    <div class="filters-top stores-top col-xs-12 col-md-6">
                    </div>

                </h5>



         
                <table width="100%" class="table responsive-table">
            <thead> <tr>
                            <th> ID </th>
                            <th> {t("Branch")}</th>
                            <th> BC</th>
                            <th> {t("Email")}</th>
                            <th> {t("MobileNumber")}</th>
                            <th> {t("WhatsAppNumber")}</th>
                            <th> {t("CreationDate")}</th>
                            <th> {t("Approved")}</th>
                            <th> </th>
                        </tr>
                    </thead>
                <tbody>



                    {currentTableData.map(data => {
                        return (
                         
                            <tr>
                            <td class="bold">00{data.Id}</td>
                            <td class="bold">{data.EnName}</td>
                            <td class="bold">{data.BusinessCustomerEnName}</td>
                            <td>{data.Email}</td>
                            <td>{data.ContactPersonMobileNo}</td>
                            <td>{data.ContactPersonWhatsappNo}</td>
                            <td>
                            {new Intl.DateTimeFormat('en-GB', {
                                    month: 'long',
                                    day: '2-digit',
                                    year: 'numeric',
                                }).format(new Date(data.CreatedOn))}


                            </td>
                            <td> {
                               data.IsApproved===1 ? "Yes" : "No"
                               }</td>

                               
 <td><a onClick={() => navigatetodetail(data.Id)}><i class="fa fa-eye"></i></a></td>


                           {/*   <td>
                                <div class="dropdown">
                                    <i class="fa fa-ellipsis-h" id="dropdown" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    </i>
                                    <ul class="dropdown-menu" aria-labelledby="dropdown">
                                      <li><a href="#">Edite</a></li>
                                      <li><a href="#">delete</a></li>
                                    </ul>
                                </div>
                            </td> */}
                        </tr> 
                            

                        );
                    })}

                </tbody>
            </table>

            {isLoading == 0 ? <h1 align='center'>Loading..</h1> : null}



            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={props.total}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
            />
        </div>


    );
}

export default Branchlistbc;
