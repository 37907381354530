
import {Routes, Route, useNavigate} from 'react-router-dom';
import React, { useState, useMemo, useEffect } from 'react';
import Pagination from '../common/pagination';
import data from '../../data/mock-data.json';

import { useTranslation } from 'react-i18next';
import helperapi from '../../service/common';
function Businesscustomerlist(props) {
    
const { t, i18n } = useTranslation();
    let PageSize = props.pagesize;

    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(0);
    const [isData, setIsData] = useState(0);

    
const navigate = useNavigate();


const navigatetodetail = (Id) => {
    navigate(`/businesscustomersbranches/${Id}`, { state: { itemid: Id } });
};


    const currentTableData = useMemo(() => {
        console.log("worked")

        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;

        setIsData(isData + 1);
        if (props.isError == 1) {
            setIsLoading(1);
        }

        if (isData >= 1) {
            setIsLoading(1);
        }

        return props.list.data ? props.list.data.slice(firstPageIndex, lastPageIndex) : [];


    }, [currentPage, props]);

    return (
        <div className="boxs col-xs-12">
        <h5> 
                    <div class="filters-top col-xs-12 col-md-6">
                        <form action="">
                       {/* <input type="text" class="search" placeholder="Search"/> */ }
                        </form>
                    </div>
                    
                    <div class="filters-top stores-top col-xs-12 col-md-6">
                    </div>

                </h5>



            <table width="100%" class="table responsive-table">
            <thead> <tr>
                            <th> ID </th>
                            <th> BC</th>
                            <th>{t("Category")}</th>
                            <th> {t("MobileNumber")}</th>
                            <th> {t("MainPersonName")}</th>
                            <th> {t("CreationDate")}</th>
                            <th> {t("Status")}</th>
                            <th> {t("Branch")}</th>
                        </tr>
                    </thead>
                <tbody>



                    {currentTableData.map(data => {
                        return (
                            <tr>
                            <td class="bold">00{data.id}</td>
                            <td class="bold"><a href="#">{data.enName}</a></td>
                            <td>{data.bcCategoryName}</td>
                            <td>{data.contactPersonMobileNo}</td>
                            <td>{data.mainContactPerson}</td>
                            <td>
                                {
helperapi.formatdate(String(data.createdOn))}

                            </td>
                       
                            <td>
                                {
                               data.ActiveStatus===1 ? "Yes" : "No"
                               }</td>
                         <td><a onClick={() => navigatetodetail(data.id)}><i class="fa fa-eye"></i></a></td>
                            
                            {/*
                            <td>
                                <div class="dropdown">
                                    <i class="fa fa-ellipsis-h" id="dropdown" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    </i>
                                    <ul class="dropdown-menu" aria-labelledby="dropdown">
                                      <li><a href="#">Edite</a></li>
                                      <li><a href="#">delete</a></li>
                                    </ul>
                                </div>
                            </td> */ }
                        </tr>

                        );
                    })}

                </tbody>
            </table>

            {isLoading == 0 ? <h1 align='center'>Loading..</h1> : null}



            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={props.total}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
            />
        </div>


    );
}

export default Businesscustomerlist;
