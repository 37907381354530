import React, { useState, useEffect } from 'react';
import ProductList from '../../component/orders/productlistfororders';
import api from '../../service/api';
import CompoundedSpace from 'antd/lib/space';

import { useProductStore } from '../../store/useProductStore';
import {Routes, Route, useNavigate} from 'react-router-dom';

import { useDashboardStore } from '../../store/useDashboardStore';



function AvailableProductsNewOrder() { 
    const [list, setList] = useState([]);
    const [listCategory, setListCategory] = useState([]);
    const [listOther, setListOther] = useState([]);
    const [total, setTotal] = useState(0);
    const [isError, setIsError] = useState(0);
  
    const navigate = useNavigate();
    

    const {products, productcategory, getProducts, getProductCategory} = useProductStore((state) => ({
        products: state.products,
        productcategory: state.productcategory,
        getProductCategory: state.getProductCategory,
        getProducts: state.getProducts,
      }));

      const {id,neworderinitdata} = useDashboardStore((state) => ({
        id: state.id,
        neworderinitdata: state.neworderinitdata,
      }));



      const navigatetoorders = () => {
        navigate('/orders');
    }

    const navigatetoordersummary = () => {
        navigate('/ordersummary');
    }


    useEffect(() => {
       if(neworderinitdata.bc==null) {
        navigate('/orders');
    }
      }, [neworderinitdata])

      

  
  useEffect(() => {
    if (products && products.products && products.products.length > 0) {
        //nothing 
        } else {
            console.log('product called')
            getProducts();
        }

    if (productcategory && productcategory.categories && productcategory.categories.length > 0) {
        //nothing
    } else {
        console.log("category called")
        getProductCategory();
    }
  }, [getProductCategory, getProducts])

  useEffect(() => {
       
        if (products && products.products && products.products.length > 0) {
            setList(products);
            setTotal(products.products.length)
          }

          if (productcategory && productcategory.categories && productcategory.categories.length > 0) {
            setListCategory(productcategory);
          }


  }, [products, productcategory])

    useEffect(() => {
       
        const json1 = [{
            "status":[{id: 1, name: "Yes"},
            {id: 2, name: "No"}]
        }];

        const json2 = [{
            "categories":[{id: 3, test: 6},
            {id: 4, test: 7},
            {id: 5, test: 8},
            {id: 6, test: 9},
            {id: 7, test: 10}]
        }];

        var finalObj = json1.concat(json2)

        setListOther(finalObj)

        
    }, []);





    return ( 
    <>

<h1> <a class="back" onClick={navigatetoorders}></a> Create New Order </h1>
         <div class="step col-xs-12">
            <div><span>1</span> Delivery Details </div>
            <div class="active"><span>2</span> Add Products </div>
            <div><span>3</span> Summary </div>
         </div>

         
         {/*
            
            <button onClick="location.href='add-product.html'" class="add-product"><span>+</span> Add Product</button> 
    */}
        <div class="product col-xs-12">
         <ProductList list={list} listcategory={listCategory} total={total} pagesize={10} isError={isError} listother={listOther}/>
        </div>
     
    </>
    ); 
} 
export default AvailableProductsNewOrder; 