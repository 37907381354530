import { Outlet, Link } from "react-router-dom";
import HeaderApp from '../../component/header';
import Sidebar from '../../component/common/sidebar';
import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';


import { useProductStore } from '../../store/useProductStore';

const Layout = () => {
  const [token, setToken] = useState(null);


  const navigate = useNavigate();
  
  /*

    const handleLogin = async () => {
    const token = await fakeAuth();

    setToken(token);
  };

  */

  
  const {reset} = useProductStore((state) => ({
    reset: state.reset,
  }));


  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    navigate('/login');
  };
  
  
  return (
    <>
    <HeaderApp />
    <div class="page-dashboard col-xs-12">
                <Sidebar token={token} onLogout={handleLogout} />   
                <div class="content">    
                <Outlet />
                </div>
            </div>
    </>
  )
};

export default Layout;