import React, { useState, useEffect } from 'react';
import List from '../../component/branch/branchuserlist';
import api from '../../service/api';
import { useParams } from 'react-router';

import {useRef} from 'react';
import { RESOURCE_URL } from "../../config/datasource";

import { useTranslation } from 'react-i18next';

import {Routes, Route, useNavigate} from 'react-router-dom';

function Branchuser() { 

    const { t, i18n } = useTranslation();

    const [list, setList] = useState([]);
    const [listCategory, setListCategory] = useState([]);
    const [listOther, setListOther] = useState([]);
    const [total, setTotal] = useState(0);
    const [isError, setIsError] = useState(0);    

    const { itemid } = useParams();


    const [formData, setFormData] = useState({});
    

     const inputRef = useRef(null);
     const [dataId, setDataId] = useState(0);
     const [type, setType] = useState('');
     const fallbackSrc = RESOURCE_URL + "/images/1_logo.png";
     const [isLogo, setIsLogo] = useState(0);
     const [isTax, setIsTax] = useState(0);
     const [isComm, setIsComm] = useState(0);

     const [qoyodCustId, setQoyodCustId] = useState();
     const [customer, setCustomer] = useState([]);
     const navigate = useNavigate();
     


     

useEffect(() => {
            

    const fetchList = async () => {
        try {
            api.generic('GET','/general/customers')               
            .then((response)=>{
                
                console.log(response.data);
                setCustomer(response.data);
            })
            .catch((error) => {
               // setIsError(1);
            })
        } catch (error) {
            //setIsError(1);
        }
    };


    fetchList();



    
}, []);


     
  const handleSubmit = (event) => {
    event.preventDefault();
   
    try {
    const data =  {
        Id:itemid,
        EnName:formData.EnName,
        ArName:formData.ArName,
        ContactPersonWhatsappNo:formData.ContactPersonWhatsappNo,
        ContactPersonMobileNo:formData.ContactPersonMobileNo,
        Description:formData.Description,
        ActiveStatus:formData.ActiveStatus,
        Address:formData.Address,
        AddressLink:formData.AddressLink,
        QoyodCustId:qoyodCustId
      };

      

    api.generic('POST','/general/branch/addupdate',data)               
    .then((response)=>{
      console.log(response.data)
        const { success, message } = response.data;
       if(success) {
        navigate('/branches');
       } else {
        alert(message)
       }
        
    })
    .catch((error) => {
        console.log('error - from server');
    })
} catch (error) {
    console.log('error - not reachable');
}

};

     
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  
    useEffect(() => {
            

        const fetchList = async () => {
            try {
                api.generic('GET','/general/branch/detail/' + itemid)               
                .then((response)=>{
                    
                    console.log(response.data);
                    //setList(response.data);
                   // setTotal(response.data.data.length);
                    
                    setFormData({ ...response.data.data[0] });


                })
                .catch((error) => {
                    setIsError(1);
                })
            } catch (error) {
                setIsError(1);
            }
        };


        fetchList();



        
    }, []);

    useEffect(() => {
            

        const fetchList = async () => {
            try {
                api.generic('GET','/general/branchusersbybranchid/' + itemid)               
                .then((response)=>{
                    
                    console.log(response.data);
                    setList(response.data);
                    setTotal(response.data.data.length);
                })
                .catch((error) => {
                    setIsError(1);
                })
            } catch (error) {
                setIsError(1);
            }
        };


        fetchList();



        
    }, []);





    return ( 
      <>

      
   <h1>{t("BranchDetail")}
          
          </h1>
 
      <div class="product col-xs-12">
         
      <div class="boxs col-xs-12"> 
                 <h5> <span>{t("BranchInfo")}</span></h5>
 
                 <form onSubmit={handleSubmit}>
                 <div class="form-add col-xs-12">
                     <div class="field col-xs-12 col-md-6">
                         <label>{t("BranchNameinEnglish")}</label>
                         <input  required type="text" class="input" placeholder="branch Name in English" value={formData.EnName || ''} id="EnName" name="EnName" onChange={handleChange}/>
                     </div>
 
                     <div class="field col-xs-12 col-md-6">
                         <label>{t("BranchnameinArabic")}</label>
                         <input required type="text" class="input" placeholder="اسم ألفرع باللغه العربيه" value={formData.ArName || ''}  id="ArName" name="ArName" onChange={handleChange}/>
                     </div>
                     
                     <div class="field col-xs-12 col-md-6">
                         <label>{t("Emailwithusername")}</label>
                         <input required type="mail" class="input" placeholder="Customer email" value={formData.Email || ''} id="Email" name="Email" />
                     </div>
                     
                     
                     <div class="field col-xs-12 col-md-6">
                         {/*
                         <label>branch manager Role (Account Type)</label>
                         <select name="" id="">
                             <option value=""> Manager</option>
                             <option value=""> Staff</option>
                         </select>
                         */ }
                     </div>
 
                     
                     
                     
                     <div class="field col-xs-12 col-md-6">
                         <label>{t("WhatsAppNumber")}</label>
                         <input required type="text" class="input" placeholder="WhatsApp" value={formData.ContactPersonWhatsappNo || ''}  id="ContactPersonWhatsappNo" name="ContactPersonWhatsappNo" onChange={handleChange}/>
                     </div>
 
                     <div class="field col-xs-12 col-md-6">
                         <label>{t("MobileNumber")}</label>
                         <input required type="text" class="input" placeholder="Mobile number" value={formData.ContactPersonMobileNo || ''}  id="ContactPersonMobileNo" name="ContactPersonMobileNo" onChange={handleChange}/>
                     </div>
 
                     <div class="field col-xs-12 col-md-6">
                        <label>{t("Address")}</label>
                        <input type="text" class="input" placeholder="Address"   value={formData.Address || ''}  id="Address" name="Address" onChange={handleChange}/>
                    </div>
                    <div class="field col-xs-12 col-md-6">
                        <label>{t("AddressLink")}</label>
                        <input type="text" class="input" placeholder="AddressLink"   value={formData.AddressLink || ''}  id="AddressLink" name="AddressLink" onChange={handleChange}/>
                    </div>

                     <div class="field col-xs-12 ">
                         <label>{t("Description")}</label>
                         <input required type="text" class="input" placeholder="Description" value={formData.Description || ''} id="Description" name="Description" onChange={handleChange}/>
                     </div>

                     <div class="field col-xs-12 col-md-6">
                       
                        <label>{t("QoyodCustomerMap")}</label>
                        <select name="QoyodCustId" required id="QoyodCustId" class="input"  onChange={e => setQoyodCustId(e.target.value)}>
                        <option value=""  >Select</option>
                        { customer?.customers?.map(data => {
                                return(
                                <option value={data.id}>{data.name}</option>
                                );
                            })}
                        </select>
                    </div>

                     <div class="field col-xs-12 col-md-6">
                        <label>{t("Status")}</label>
                        <select required  value={formData.ActiveStatus || '0'}  id="ActiveStatus" name="ActiveStatus" onChange={handleChange}>
                            <option value="">Select</option>
                            <option value="1">Approved</option>
                            <option value="0">Denied</option>
                        </select>
                    </div>

                     <hr/>


<div class="field col-xs-12 col-md-6">
    <div class="create-product col-xs-12"> 
        <button class="create" type="submit">{t("Save")}</button>
    </div>
</div>
          
                 </div>  
</form>
             </div>
      </div>        

   <h1>{t("BranchUsers")}
         </h1>

     <div class="product col-xs-12">
        
        
     <List list={list} total={total} pagesize={10} isError={isError} />
     </div>
   </>
    ); 

} 

export default Branchuser; 

