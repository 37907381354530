
import React, { useState, useMemo, useEffect } from 'react';
import Pagination from '../common/pagination';
import data from '../../data/mock-data.json';
import {Routes, Route, useNavigate} from 'react-router-dom';

import Popup from 'reactjs-popup';

import 'reactjs-popup/dist/index.css';
import xor from 'lodash/xor';

import xorBy from 'lodash/xor';


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import api from '../../service/api';


import { useDashboardStore } from '../../store/useDashboardStore';
import { RESOURCE_URL } from "../../config/datasource";

function Productlistforfavourites(props) {



let PageSize = props.pagesize;

const [currentPage, setCurrentPage] = useState(1);
const [isLoading, setIsLoading] = useState(0);
const [isData, setIsData] = useState(0);
const [checked, setChecked] = useState([]);  
const [inputListName, setInputListName] = useState('');
const [inputBranchID, setInputBranchID] = useState('');


const [productFilter, setProductFilter,productFilterRef] = useState('');
const [productFilterCount, setProductFilterCount,productFilterCountRef] = useState(0);


const [branchList, setBranchList] = useState([]);

const [open, setOpen] = useState(false);

const [proqty, setProQty] = useState([]);  
const [proprice, setProPrice] = useState([]);  

let [isOpen, setIsOpen] = useState(true)


const navigate = useNavigate();



const {id,newrfqinit,rfqproducts,addRfqProducts,removeRfqProducts,resetrfq} = useDashboardStore((state) => ({
  id: state.id,
  newrfqinit: state.newrfqinit,
  rfqproducts: state.rfqproducts,
  addRfqProducts: state.addRfqProducts,
  removeRfqProducts: state.removeRfqProducts,
  resetrfq: state.resetrfq
}));


const placeholderImage =
'img/featured.png'



const handleQtyChange = (id,sku, e) => {
  var tempQty=[];

  tempQty=proqty.filter(a => a.ProductId !== id)
  console.log(tempQty)
  setProQty([
    ...tempQty,
    {sku:sku,ProductId: id, BranchUserId:1 , qty:Number(e.target.value)}
  ]);

}


     
useEffect(() => {
            

  const fetchList = async () => {
      try {
          api.generic('GET','/general/branchandbc/active')               
          .then((response)=>{
              
              setBranchList(response.data);
          })
          .catch((error) => {
          })
      } catch (error) {
      }
  };


  fetchList();



  
}, []);


const handlePriceChange = (id,sku, e) => {
  var tempPrice=[];

  tempPrice=proprice.filter(a => a.ProductId !== id)
  console.log(tempPrice)
  setProPrice([
    ...tempPrice,
    {sku:sku,ProductId: id, BranchUserId:1 , price:parseFloat(e.target.value)}
  ]);

}


const navigatetodetail = (Id) => {

    navigate(`/productdetail/${Id}`, { state: { id: Id } });
};

  const handleClose = () => {
    setOpen(false);
  };

  
  const handleProceed = () => {
   // setOpen(false);
   const detail=[]
   if(checked.length>0) {
     
    checked.forEach((id, index) => {
      var tmpQty=[]
      var tmpPrice=[]
      tmpQty=proqty.filter(a => a.sku == id)
      tmpPrice=proprice.filter(a => a.sku == id)

      const prodata = props.list.products.filter( (pro) => pro.sku.includes(id))
      detail.push({sku:prodata[0].sku,ProductId: prodata[0].id,ProductName: prodata[0].name_en, BranchUserId:1, qty:tmpQty[0].qty, price:tmpPrice[0].price })
    });



    console.log(detail)

    
    const data =  {
        EnName:inputListName,
        ArName:inputListName,
        BranchFavouriteListProducts: detail,
        BranchId: inputBranchID
      };

      console.log(data)

    try {
      //  api.generic('POST','/general/branchfavouritelist/addupdate',data)     
        api.generic('POST','/general/rfqs/addupdateadmin',data)             
        .then((response)=>{
            navigate('/favourites',{state:{load:1}});
            console.log(response.data);
        })
        .catch((error) => {
          console.log(error)
            alert("Technical issue. Please Try Again");
        })
    } catch (error) {
      console.log(error)
        alert("Technical issue. Please Try Again");
    }
    
    
  } else {
    
    alert("Couldn't create list as none of the products selected");
  }

  };

const submitFavourites = () => {
     //setOpen(true);
     if(inputBranchID.length>0 && inputListName.length>0) {
      handleProceed()
     } else {

      alert("Please fill up the fields")
     }
  };

  /*
  const handleChange = id => () => {
    setChecked(prev => {
      if (prev.includes(id)) {
        return prev.filter(x => x !== id);
      } else {
        return [...prev, id];
      }
    });
  };
*/

const handleChange = (id,sku,selling_price) => () => {
  setChecked(prev => xor(prev, [sku]));
  var tempQty=[];
  tempQty=proqty.filter(a => a.ProductId !== id)
  
  setProQty([
    ...tempQty,
    {sku:sku,ProductId: id, BranchUserId:1 , qty:1}
  ]);

  var tempPrice=[];

  tempPrice=proprice.filter(a => a.ProductId !== id)
  console.log(tempPrice)
  setProPrice([
    ...tempPrice,
    {sku:sku,ProductId: id, BranchUserId:1 , price:parseFloat(selling_price)}
  ]);

  };


useEffect(() => {
    console.log(props.listother)
}, [props]);   

const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    
    setIsData(isData+1);
    if(props.isError==1) {
        setIsLoading(1);
    }

    if(isData>=2) {
       setIsLoading(1);
    }

    
    if(productFilter.length>0) {
    
      setProductFilterCount(props.list.products.filter(dt => productFilter.length>0?dt.sku==productFilter:1==1)?props.list.products.filter(dt => productFilter.length>0?dt.sku==productFilter:1==1).length:0)
       return props.list.products.filter(dt => productFilter.length>0?dt.sku==productFilter:1==1) ? props.list.products.filter(dt => productFilter.length>0?dt.sku==productFilter:1==1).slice(firstPageIndex, lastPageIndex) : [];
     } else {
       setProductFilterCount(props.list.products?props.list.products.length:0)
     return props.list.products ? props.list.products.slice(firstPageIndex, lastPageIndex) : [];
     }

   // return props.list.products ? props.list.products.slice(firstPageIndex, lastPageIndex) : [];

  }, [currentPage,props,productFilter]);


  
  const onImageError = (e) => {
    e.target.src = placeholderImage
    }


    return (
        <div className="boxs col-xs-12"> 
        
        <h5> 
            <span><img src="img/icon2.png" alt=""/> {props.total} Available Products</span>

            <div class="selected">{ checked.length } selected product</div>

            <div className="filters-top col-xs-12 col-md-7">
                
                <form action="">
                    <input type="text" className="search" placeholder="Search" onChange={(e) => { setProductFilter(e.target.value);  }}/>
                        <select class="category">
                        { props.listcategory.categories?.map(data => {
                            return(
                            <option value={data.id}>{data.name}</option>
                            );
                        })}
                        </select>
                            <Popup trigger={<div class="but create"  >Create</div>}  position="left center" 
  closeOnDocumentClick closeOnEscape > 
   {close => (
    <div>
<div class="created-pup created-pup-on">
    <div class="box col-xs-12 col-md-4">
        <h2>Name the fav list you created</h2>  
        
        <button     style={{ width : '30px',alignSelf: 'flex-end'}}
 onClick={() => {
              close(); }}>
      X
    </button>
       
            <label>Fav list name</label>
            <input required type="text" class="input" placeholder="Enter the fav list name"  onChange={(e) => { setInputListName(e.target.value);  }}    />

            
            <label>Behalf of Branch</label>

            <select class="input"  onChange={(e) => {  setInputBranchID(e.target.value);  }}  required>
              
            <option value="">Select</option>

                        {branchList.data?.map(data => {
                            return(
                            <option value={data.Id}>{data.BusinessCustomerEnName} - {data.EnName}</option>
                            );
                        })}
                        </select>
            <button  class="submit" onClick={() => submitFavourites()} >Confirm</button>
          
        
    </div></div>
</div> )}
  </Popup>

                </form>




            </div>

        </h5>


        <table width="100%" class="table responsive-table">
            <thead>
                <tr>
                            <th><input type="checkbox" class="all"/></th>
                    <th> ID</th>
                    <th> SKU</th>
                    <th> Product</th>
                    <th> Qty</th>
                    <th> Bar Code</th>
                    <th> Category</th>
                    <th> UoM</th>
                    <th> Retail Pricing</th>
                    <th> Your Price</th>
                    <th> </th>
                </tr>
            </thead>
            <tbody>



             {currentTableData.map(data => {
					return (
                        
                        <tr key={data.id}> <td><input type="checkbox"  onChange={handleChange(data.id, data.sku,data.selling_price)}
                        checked={checked.includes(data.sku)} /></td>
                        <td className="bold">{data.id} </td>
                        <td className="bold">{data.sku} </td>
                        <td>
<img
              src={RESOURCE_URL +  '/product/' + data.id + '_img.png' ? RESOURCE_URL +  '/product/' + data.id + '_img.png' : placeholderImage}
              alt=""
              onError={onImageError}
            /> <b>{data.name_en} </b></td>
                        <td>

<input type="number"   
class="form-control text-center" value={proqty.filter(a => a.ProductId == data.id).length!=0?proqty.filter(a => a.ProductId == data.id)[0].qty:"0"} onChange={(e) => handleQtyChange(data.id, data.sku, e)}  />

</td>

                        <td>{data.barcode}</td>
                        <td className="bold"><a href="#">
                        
                        {props.listcategory?.categories.filter(cat=>cat.id==data.category_id  ).map(fillcat => (
     
                       <span>{fillcat.name}</span>
                          ))}

                        </a></td>
                        <td>{data.unit}</td>
                        <td>SAR {data.selling_price}</td>
                        <td>

<input type="number"   
class="form-control text-center" value={proprice.filter(a => a.ProductId == data.id).length!=0?proprice.filter(a => a.ProductId == data.id)[0].price:data.selling_price} onChange={(e) => handlePriceChange(data.id, data.sku, e)}  />

</td>
                                <td><a onClick={() => navigatetodetail(data.id)}><i class="fa fa-eye"></i></a></td>
                    </tr>

					);
				})}


                
            </tbody>
        </table>

        

        {isLoading == 0 ?  <h1 align='center'>Loading..</h1> : null}
     
        <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={productFilterCount}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)}
      />


<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleProceed} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>


   </div>
     

    );
  }
  
  export default Productlistforfavourites;
  