import React, { useState, useEffect } from 'react';
import CompanyList from '../../component/company/companylist';
import api from '../../service/api';


function Companies() { 

    const [list, setList] = useState([]);
    const [listCategory, setListCategory] = useState([]);
    const [listOther, setListOther] = useState([]);
    const [total, setTotal] = useState(0);
    const [isError, setIsError] = useState(0);
        
    useEffect(() => {
            

        const fetchList = async () => {
            try {
                api.generic('GET','/general/company')               
                .then((response)=>{
                    
                    console.log(response.data);
                    setList(response.data);
                    setTotal(response.data.data.length);
                })
                .catch((error) => {
                    setIsError(1);
                })
            } catch (error) {
                setIsError(1);
            }
        };


        fetchList();



        
    }, []);

    return ( 
      <>
      <h1>All Companies
      </h1>


     <div class="product col-xs-12">
        
     <CompanyList list={list} listcategory={listCategory} total={total} pagesize={3} isError={isError} />
     </div>
   </>
    ); 
} 
export default Companies; 