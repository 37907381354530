import React, { useState, useEffect } from 'react';
import ProductList from '../../component/product/productlist';
//import api from '../service/api';
import { useProductStore } from '../../store/useProductStore';
import { useDashboardStore } from '../../store/useDashboardStore';
import {Routes, Route, useNavigate} from 'react-router-dom';

import { useTranslation } from "react-i18next";

function Products() { 
    const [list, setList] = useState([]);
    const [listCategory, setListCategory] = useState([]);
    const [listOther, setListOther] = useState([]);
    const [total, setTotal] = useState(0);
    const [isError, setIsError] = useState(0);
    

    const navigate = useNavigate();

    const { i18n,t } = useTranslation();
    
    const {id} = useDashboardStore((state) => ({
        id: state.id,
      }));

    const {products, productcategory, getProducts, getProductCategory} = useProductStore((state) => ({
        products: state.products,
        productcategory: state.productcategory,
        getProductCategory: state.getProductCategory,
        getProducts: state.getProducts,
      }));


      const navigatetodetail = (Id) => {
        navigate(`/productdetail/${Id}`, { state: { id: Id } });
    };
    
  
  useEffect(() => {
    if (products && products.products && products.products.length > 0) {
        //nothing 
        } else {
            console.log('product called')
            getProducts();
        }

    if (productcategory && productcategory.categories && productcategory.categories.length > 0) {
        //nothing
    } else {
        console.log("category called")
        getProductCategory();
    }
  }, [getProductCategory, getProducts])

  useEffect(() => {
       
        if (products && products.products && products.products.length > 0) {
            setList(products);
            setTotal(products.products.length)
          }

          if (productcategory && productcategory.categories && productcategory.categories.length > 0) {
            setListCategory(productcategory);
          }

  }, [products, productcategory])




    useEffect(() => {

        const json1 = [{
            "status":[{id: 1, name: "Yes"},
            {id: 2, name: "No"}]
        }];

        const json2 = [{
            "categories":[{id: 3, test: 6},
            {id: 4, test: 7},
            {id: 5, test: 8},
            {id: 6, test: 9},
            {id: 7, test: 10}]
        }];

        var finalObj = json1.concat(json2)

        setListOther(finalObj)

        
    }, []);



    return ( 
    <>
      <h1> {t("Product")} </h1>
      <div class="product col-xs-12">
        <ProductList list={list} listcategory={listCategory} total={total} pagesize={10} isError={isError} listother={listOther}/>
      </div>
    </>
    ); 
} 
export default Products; 

