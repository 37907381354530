
import React, { useState, useMemo, useEffect } from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';

import { RESOURCE_URL } from "../../config/datasource";

   

function FavouriteCard(props) {
   

const placeholderImage =
'img/pro1.png'


const onImageError = (e) => {
    e.target.src = placeholderImage
    }

    return (
        <div class="item-products">
            {props.param ? (
                <>  <img
                src={RESOURCE_URL +  '/product/' + props.productid + '_img.png' ? RESOURCE_URL +  '/product/' + props.productid  + '_img.png' : placeholderImage}
                alt=""
                onError={onImageError}
              />
        <h4>SAR {props.param[0]?.selling_price} <span>( Per unit )</span></h4>
        <p>{props.param[0]?.name_en}</p>
        <div class="uom">UoM: {props.param[0]?.unit}</div>
        <h3>SKU ({props.param[0]?.sku})</h3>
        <div class="quantity">
            Quantity <strong>{props.qty}</strong>
        </div>
        <div class="covers">
            {/*
            <a href="#"><img src="img/deliet.png" alt=""/></a>
            <a href="#"><img src="img/edit.png" alt=""/></a>
            */ }
        </div></>
) : ('')
}    </div>

    );
}

export default FavouriteCard;