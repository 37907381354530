
import useState from 'react-usestateref'
import React, { useMemo, useEffect } from 'react';
import Pagination from '../common/pagination';
import data from '../../data/mock-data.json';

import { Routes, Route, useNavigate } from 'react-router-dom';


import { useTranslation } from 'react-i18next';

import api from '../../service/api';

import { useRef } from 'react';

import { RESOURCE_URL } from "../../config/datasource";
import moment from 'moment';



function FinanceList(props) {
    const { t, i18n } = useTranslation();

    let PageSize = props.pagesize;

    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(0);
    const [orderMain, setOrderMain, orderMainRef] = useState([]);
    const [orderDetail, setOrderDetail, orderDetailRef] = useState([]);
    const [isData, setIsData] = useState(0);
    const [dataId, setDataId] = useState(0);

    const inputRef = useRef(null);

    const navigate = useNavigate();

    const navigatetodetail = (Id) => {


        navigate(`/orderindividual/${Id}`, { state: { id: Id } });
    };


    const upload = (Id) => {

        alert("uploading..")
    };

    const createpdf = (Id, QId) => {
        const data = {
            Id: Id,
            QoyodInvoiceId: QId
        };

        try {
            api.generic('POST', '/general/showpdf', data)
                .then((response) => {
                    console.log(response)
                    /*
                  {
          "pdf_file": "https://cdn.qoyod.com/export/pdf/7d1c5b75b56cf7813e36c2b08228f7567d5834b5/OlATdab0stUX11DO2zt2IA/VBf9wrSyR4IkGwMFCP_ZRg.pdf",
          "expire_at": "Sun, 21 Jan 2024 15:03:12 GMT"
      }
                    */
                    console.log(response.data.pdf_file)
                    changepdf(Id, QId, response.data.pdf_file)
                    alert("Pdf " + response.data.pdf_file + " Generated. Pdf do expire from time to time. You might need to regenerate if they expire ")
                    //if(response.invoice.id)


                })
                .catch((error) => {
                    console.log(error)
                    alert("Technical issue. Please Try Again. " + "There might be issues with inventory. Please check it");
                })
        } catch (error) {
            console.log(error)
            alert("Technical issue. Please Try Again." + "There might be issues with inventory. Please check it");
        }

    };

    const createinvoice = (Id) => {

        try {
            api.generic('GET', '/general/orders/products/' + Id)
                .then((response) => {
                    setOrderDetail(response.data);


                    try {
                        api.generic('GET', '/general/orders/' + Id)
                            .then((response) => {
                                // console.log(response.data);
                                setOrderMain(response.data);

                                console.log(orderMainRef.current)
                                console.log(orderDetailRef.current)
                                var jsonitems = [];


                                {
                                    orderDetailRef.current.map((dt) =>
                                        jsonitems.push({ product_id: dt.ProductId, description: "", quantity: dt.Quantity, unit_price: dt.UnitPrice, discount: 0, description: "percentage", tax_percent: 15 })

                                    )
                                }
                                //2549
                                //delivery fees  DeliveryCharges
                                //jsonitems.push({product_id: 280094536099, description: "", quantity:0 , unit_price: orderMainRef.current[0].DeliveryCharges, discount: 0, description:"percentage", tax_percent:0})
                                jsonitems.push({ product_id: 1700, description: "", quantity: 1, unit_price: orderMainRef.current[0].DeliveryCharges, discount: 0, description: "percentage", tax_percent: 0 })

                                // jsonitems.push({product_id: 1241, description: "", quantity:6 , unit_price: 23, discount: 0, description:"percentage", tax_percent:15})

                                //jsonitems.push({product_id: 1241, description: "", quantity:6 , unit_price: 23, discount: 0, description:"percentage", tax_percent:15})
                                //console.log(jsonitems)

                                //"line_items": [JSON.parse(JSON.stringify(jsonitems))]
                                const data = {
                                    "invoice": {
                                        "contact_id": 606,
                                        "reference": "b2b-invoice-ref--" + Math.random() * (100 - 1) + 1 + Id,
                                        "description": "b2b invoice #" + orderMainRef.current[0].Id,
                                        "issue_date": moment().format('YYYY-MM-DD'),
                                        "due_date": moment().format('YYYY-MM-DD'),
                                        "status": "Approved",
                                        "inventory_id": 1,
                                        "line_items": [
                                            {
                                                "product_id": 1241,
                                                "description": "",
                                                "quantity": 1,
                                                "unit_price": 189.2,
                                                "discount": 0,
                                                "discount_type": "percentage",
                                                "tax_percent": 15
                                            }
                                        ]
                                    }
                                };

                                // console.log(data)

                                console.log("Trying")
                                const jss = [{ "product_id": 1241, "description": "", "quantity": 1, "unit_price": 196.80, "discount": 0, "description": "percentage", "tax_percent": 15 }, { "product_id": 1241, "description": "", "quantity": 1, "unit_price": 96.80, "discount": 0, "description": "percentage", "tax_percent": 15 }];


                                /*
                                 const data2 =  {
                                  "invoice": {
                                      "contact_id": 606,
                                      "reference": "b2bN-invoice-ref--" + Math.random() * (100 - 1) + 1 + Id ,
                                      "description": "b2b invoice #" + orderMainRef.current[0].Id ,
                                      "issue_date": moment().format('YYYY-MM-DD'), 
                                      "due_date": moment().format('YYYY-MM-DD'),
                                      "status": "Approved",
                                      "inventory_id": 1,
                                      "line_items":jss
                                  }
                              };
                        */



                                const data2 = {
                                    "invoice": {
                                        "contact_id": 606,
                                        "reference": "b2bN-invoice-ref--" + Math.random() * (100 - 1) + 1 + Id,
                                        "description": "b2b invoice #" + orderMainRef.current[0].Id,
                                        "issue_date": moment().format('YYYY-MM-DD'),
                                        "due_date": moment().format('YYYY-MM-DD'),
                                        "status": "Approved",
                                        "inventory_id": 1,
                                        "line_items": jsonitems
                                    }
                                };
                                console.log(data2)




                                try {
                                    api.generic('POST', '/general/createinvoice', data2)
                                        .then((response) => {
                                            console.log(response)
                                            /*
                                            {"invoice":{"id":15513,"description":"b2b invoice","issue_date":"2023-10-30","due_date":"2023-10-30","due_amount":"677.58","paid_amount":"0.0","total":"677.58","contact_id":606,"status":"Approved","reference":"b2b-invoice-ref-139","notes":null,"terms_conditions":null,"qrcode_string":"AR7YtNix2YPYqSDYs9i52LEg2YTZhNiq2KzYp9ix2KkCDzMxMDQ5NTMzODgwMDAwMwMTMjAyMy0xMC0zMFQxNzozNzozNQQGNjc3LjU4BQU4OC4zOA==","payment_method":null,"created_at":"2024-01-19T17:37:35.000+03:00","updated_at":"2024-01-19T17:37:35.000+03:00","created_by":"","line_items":[{"product_id":1241,"product_name":"( 100pcs*1box ) CHEMEX FILTERS ","description":"","quantity":"1.0","unit_price":"589.2","unit_type":4,"unit":"كرتون","discount_percent":"0.0","discount_amount":"0.0","tax_percent":"15.0","line_total":"677.58","inventory_id":1,"is_inclusive":false,"inclusive_unit_price":null,"unrounded_vat_value":"88.38"}],"payments":[]}}
                                      
                                            */
                                            console.log(response.data.invoice.id)
                                            changeinvoiceid(Id, response.data.invoice.id)
                                            alert("Invoice " + response.data.invoice.id + " Generated In Qoyod")
                                            //if(response.invoice.id)


                                        })
                                        .catch((error) => {
                                            console.log(error)
                                            alert("Technical issue. Please Try Again. " + "There might be issues with INVENTORY QTY NOT AVAILABLE IN QOYOD. Please check it");
                                        })
                                } catch (error) {
                                    console.log(error)
                                    alert("Technical issue. Please Try Again." + "There might be issues with INVENTORY QTY NOT AVAILABLE IN QOYOD. Please check it");
                                }





                            })
                            .catch((error) => {
                            })
                    } catch (error) {
                    }

                })
                .catch((error) => {
                })
        } catch (error) {
        }



        /*
           const data =  {
               "invoice": {
                   "contact_id": 606,
                   "reference": "b2b-invoice-ref--" + Math.random() * (100 - 1) + 1 + Id ,
                   "description": "b2b invoice " ,
                   "issue_date": "2023-10-30",
                   "due_date": "2023-10-30",
                   "status": "Approved",
                   "inventory_id": 1,
                   "line_items": [
                       {
                           "product_id": 1241,
                           "description": "",
                           "quantity": 1,
                           "unit_price": 589.2,
                           "discount": 0,
                           "discount_type": "percentage",
                           "tax_percent": 15
                       }
                   ]
               }
           };
           */

        //paste here

    };





    const changepdf = (Id, QoyodInvoiceId, PdfPath) => {
        const data = {
            Id: Id,
            QoyodInvoiceId: QoyodInvoiceId,
            PdfPath: PdfPath
        };
        try {
            api.generic('POST', '/general/orders/changepdf', data)
                .then((response) => {
                    if (response.data.success == true) {
                        window.location.reload();
                    } else {
                        alert("Technical issue. Please Try Again");
                    }
                })
                .catch((error) => {
                    console.log(error)
                    alert("Technical issue. Please Try Again");
                })
        } catch (error) {
            console.log(error)
            alert("Technical issue. Please Try Again");
        }
    };

    const changeinvoiceid = (Id, QoyodInvoiceId) => {
        const data = {
            Id: Id,
            QoyodInvoiceId: QoyodInvoiceId
        };
        try {
            api.generic('POST', '/general/orders/changeqoyodinvoice', data)
                .then((response) => {
                    if (response.data.success == true) {
                        window.location.reload();
                    } else {
                        alert("Technical issue. Please Try Again");
                    }
                })
                .catch((error) => {
                    console.log(error)
                    alert("Technical issue. Please Try Again");
                })
        } catch (error) {
            console.log(error)
            alert("Technical issue. Please Try Again");
        }
    };


    const handleUpload = (Id) => {
        // 👇️ open file input box on click of another element
        setDataId(Id)
        inputRef.current.click();
    };

    function getExtension(filename) {
        return filename.split('.').pop()
    }

    const handleFileChange = event => {
        console.log(dataId)
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }

        console.log('fileObj is', fileObj);

        // 👇️ reset file input
        event.target.value = null;

        // 👇️ is now empty
        console.log(event.target.files);

        // 👇️ can still access file object here
        console.log(fileObj);
        console.log(fileObj.name);

        if (getExtension(fileObj.name.toLowerCase()) != "pdf") {
            alert("File extension should be .pdf " + "Currently extension is " + "." + getExtension(fileObj.name.toLowerCase()))
            return false;
        }

        const formData = new FormData();
        formData.append("Id", dataId);
        formData.append("file", fileObj);
        formData.append("name", fileObj.name);


        try {

            api.generic('POST', '/general/uploadinvoice', formData)
                .then((response) => {

                    console.log(response.data);
                    if (response.data.success == true) {
                        alert("invoice uploaded successfully")
                        window.location.reload();

                    }

                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
        }


    };

    const currentTableData = useMemo(() => {
        console.log("worked")

        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;

        setIsData(isData + 1);
        if (props.isError == 1) {
            setIsLoading(1);
        }

        if (isData >= 1) {
            setIsLoading(1);
        }

        return props.list.data ? props.list.data.slice(firstPageIndex, lastPageIndex) : [];


    }, [currentPage, props]);

    return (
        <div className="boxs col-xs-12">

            <div class="filters-top col-xs-12 col-md-5">
            </div>

            <div class="filters-top col-xs-12 col-md-7">
            </div>



            <table width="100%" class="table responsive-table">
                <thead> <tr>
                    <th> OrderID</th>
                    <th> BC</th>
                    <th> {t("Branch")}</th>
                    <th> {t("OrderDate")}</th>
                    <th> {t("DeliveryType")}</th>
                    <th> {t("QoyodInvId")}</th>
                    <th> {t("PaymentStatus")}</th>
                    <th> {t("InvoiceStatus")}</th>
                    <th> {t("Totalamount")}</th>
                    <th> </th>
                </tr>
                </thead>
                <tbody>



                    {currentTableData.map(data => {
                        return (

                            <tr>
                                <td class="bold">00{data.Id}</td>
                                <td class="bold">{data.BcName}</td>
                                <td class="bold">{data.BranchName}</td>
                                <td>
                                    {new Intl.DateTimeFormat('en-GB', {
                                        month: 'long',
                                        day: '2-digit',
                                        year: 'numeric',
                                    }).format(new Date(data.CreatedOn))}
                                </td>
                                <td>{data.DeliveryType}</td>
                                <td class="bold">{data.QoyodInvoiceId}<br />
                                    {data.QoyodInvoiceId ?
                                        (<a onClick={() => createpdf(data.Id, data.QoyodInvoiceId)}>Generate Pdf</a>) : (<></>)
                                    }
                                    <br />
                                    {data.PdfPath ?
                                        (<a target="about:blank" href={data.PdfPath}>Download Pdf</a>) : (<></>)
                                    }

                                </td>
                                {data.PaymentStatus == 'Unpaid' ? (<td><span class="unpaid">{data.PaymentStatus}</span></td>) : (<td><span class="paid">{data.PaymentStatus}</span></td>)}

                                <td><span>
                                    {data.CustomerInvoicePath == null ? (<span>Not Uploaded</span>) : (<span>Uploaded <a target="about:blank" href={RESOURCE_URL + '/invoices/' + data.CustomerInvoicePath}> (Show)</a></span>)}

                                </span></td>
                                <td class="bold">SAR {data.TotalAmount}</td>



                                <td>
                                    <div class="dropdown">
                                        <i class="fa fa-ellipsis-h" id="dropdown" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        </i>
                                        <ul class="dropdown-menu" aria-labelledby="dropdown">
                                            <li><a onClick={() => navigatetodetail(data.Id)}>View</a>
                                            </li>
                                            <li><a onClick={() => createinvoice(data.Id)}>Create Invoice in Qoyod</a>
                                            </li>
                                            <li><a onClick={() => handleUpload(data.Id)}>Upload Customer invoice</a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>

                        );
                    })}

                </tbody>
            </table>

            {isLoading == 0 ? <h1 align='center'>Loading..</h1> : null}



            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={props.total}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
            />

            <input
                style={{ display: 'none' }}
                ref={inputRef}
                type="file"
                onChange={handleFileChange}
            />

        </div>


    );
}

export default FinanceList;
