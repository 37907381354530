import axiosHttp from './axiois';

const api = {

    getlist: (apimethod, urlext) =>  axiosHttp({
        'method': apimethod.toUpperCase(),
        'url': urlext,
        'params': {
            'data1': 'compact',
            'data2': 'json'
        },
    }),

    login: (apimethod, urlext, params) => {
        if (apimethod == "GET") {
            return axiosHttp({
                'method': apimethod.toUpperCase(),
                'url': urlext,
                'params': params,
            });
        } else {
            return axiosHttp.post(urlext, params)
        }
    },

     generic:  (apimethod, urlext, params) => {
        if (apimethod == "GET") {
            return axiosHttp({
                'method': apimethod.toUpperCase(),
                'url': urlext,
                'params': params,
            });
        } else {
            return axiosHttp.post(urlext, params)
        }
    }
} 
export default api;

/*
import axiosHttp from "./utils/axios"; 

const getData = async ()=>{
  try{
     const response = await axiosHttp.get('/path')
     return resposne;

  }
  catch(error){
     //handle error here...
  }

}
*/