import React, { useState, useEffect } from 'react';
import api from '../../service/api';
import {Routes, Route, useNavigate} from 'react-router-dom';


import { useProductStore } from '../../store/useProductStore';
import { useDashboardStore } from '../../store/useDashboardStore';

function OrderSummary() { 

    const navigate = useNavigate();
    const [total, setTotal] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);


      const {id,neworderinitdata,orderproducts} = useDashboardStore((state) => ({
        id: state.id,
        neworderinitdata: state.neworderinitdata,
        orderproducts: state.orderproducts
      }));



      const {products, productcategory, getProducts, getProductCategory} = useProductStore((state) => ({
        products: state.products,
        productcategory: state.productcategory,
        getProductCategory: state.getProductCategory,
        getProducts: state.getProducts,
      }));
      

    useEffect(() => {
        console.log(neworderinitdata)
        if(neworderinitdata.bc==null) {
         navigate('/orders');
     }
       }, [neworderinitdata])


       useEffect(() => {
     console.log(orderproducts)
     var total=0
     var gtotal=0
     orderproducts?.map((data, idx) => (

        products?.products?.filter(value => value.sku === data.sku).map(obj =>
           {
            total=total + orderproducts.filter(a => a.productid == obj.id)[0].qty*obj.selling_price
            
            gtotal=gtotal + orderproducts.filter(a => a.productid == obj.id)[0].qty*obj.selling_price  +  parseFloat(obj.selling_price/100*15) * orderproducts.filter(a => a.productid == obj.id)[0].qty 

           }
            )
     ))   
     setTotal(total)
     setGrandTotal(gtotal +neworderinitdata.deliveryCharge)

       }, [orderproducts])
       


    const navigatetoavailableproductneworder = () => {
        navigate('/availableproductsneworder');
    }
    const createorder = () => {

        try {

            const detail=[]


   
   orderproducts?.map((data, idx) => (

    products?.products?.filter(value => value.sku === data.sku).map(obj =>
       {
        detail.push({UnitPrice:obj.selling_price,sku:obj.sku,ProductId: obj.id, ProductName: obj.name_en, Quantity:orderproducts.filter(a => a.productid == obj.id)[0].qty })
       }
        )
 ))  


            const data =  {
                Title:'customer',
                DeliveryTypeId:neworderinitdata.deliverytypeid,
                DeliveryCharges:neworderinitdata.deliveryCharge,
                DeliveryChargesWithVAT:0,
                Address:neworderinitdata.address,
                AddressLink:neworderinitdata.addressLink,
                Email:neworderinitdata.email,
                Mobile:neworderinitdata.mobile,
                BranchName:neworderinitdata.branchName,
                BCId:neworderinitdata.bc,
                BranchId:neworderinitdata.branchId,
                TotalOrderPrice:total,
                TotalVATAmount:0,
                TotalAmount:grandTotal,
                ExpDeliveryDay:neworderinitdata.deliveryDays,
                orderDetails:detail
              };



        
              alert("Processing order please click ok and wait...")
            api.generic('POST','/general/order/addupdate',data)               
            .then((response)=>{
              console.log(response.data)
                const { success, message } = response.data;
               if(success) {
                
                navigate('/orders');
               } else {
                alert(message)
               }
                
            })
            .catch((error) => {
                
              alert("Technical Error...")
                console.log('error - from server');
            })
        } catch (error) {
            alert("Technical Error...")
            console.log('error - not reachable');
        }

    }

    const navigatetoorders= () => {
        navigate('/orders');
    }

    return ( 
      <>  <h1> <a class="back" onClick={navigatetoorders}></a> Create New Order </h1>
      <div class="step col-xs-12">
         <div><span>1</span> Delivery Details </div>
         <div><span>2</span> Add Products </div>
         <div class="active"><span>3</span> Summary </div>
      </div>


      <div class="boxs col-xs-12"> 
         <div class="total-vat col-xs-12">
             <div>
                 <span>Total amount Excl VAT:  <strong>SAR {total}</strong></span>
                 <span>Delivery charges: <strong>SAR {neworderinitdata.deliveryCharge}</strong></span>
                 <span>VAT: <strong>15%</strong></span>
             </div>
             <div><span class="grand">Grand Total: <strong>{Number(grandTotal).toFixed(2)}</strong></span></div>
         </div>

         <table width="100%" class="table responsive-table">
             <thead>
                 <tr>
                     <th> line item </th>
                     <th> Product </th>
                     <th> Quantity </th>
                     <th> Unit Price (SAR)</th>
                     <th> VAT (15%)</th>
                     <th> Total amount incl. VAT  </th>
                 </tr>
             </thead>
             <tbody>

                
             {orderproducts?.map((data, idx) => (

products?.products?.filter(value => value.sku === data.sku).map(obj =>
<tr>
<td class="bold">00{obj.id}</td>
<td class="bold">{obj.name_en}</td>
<td> {orderproducts.filter(a => a.productid == obj.id)[0].qty} </td>
<td>SAR {obj.selling_price}</td>
<td>SAR {(obj.selling_price/100*15).toFixed(2)}</td>
<td>SAR {(parseFloat(obj.selling_price) + parseFloat(obj.selling_price/100*15)).toFixed(2)}</td>
</tr>
)
  


))}
 
             </tbody>
         </table>

     
     </div>






     <div class="product col-xs-12">
         <div class="boxs create-product col-xs-12"> 
             <button onClick={createorder}  class="create">Create Order</button>
             <button class="cancel" onClick={navigatetoorders}>Cancel</button>
         </div>
     </div>
   </>
    ); 

} 

export default OrderSummary; 