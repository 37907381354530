
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';


    const Sidebar = ({ token, onLogout }) => {

        const { t, i18n } = useTranslation();


    return (
        <div class="sidebar">
        <ul>
            <li>
            <NavLink to="/" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}
><img src="img/icon1.png" alt=""/>{t('Dashboard')}</NavLink>

            
            </li>
            <li>
            <NavLink to="/products" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}
><img src="img/icon2.png" alt=""/> {t('Products')}</NavLink>
            
            </li>

            <li>
            <NavLink to="/favourites" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}
><img src="img/icon3.png" alt=""/> {t('FavLists')}</NavLink>
            
            </li>
            <li>
            <NavLink to="/quotes" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}
><img src="img/icon3.png" alt=""/> {t('Quotes')}</NavLink>
            
            </li>
            <li>
            <NavLink to="/orders" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}
><img src="img/icon4.png" alt=""/>  {t('Orders')}</NavLink>

                            
            </li>

            <li>
            <NavLink to="/finances" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}
><img src="img/icon5.png" alt=""/>  {t('Finances')}</NavLink>
            
            </li>
            {

/*
            <li>
            <NavLink to="/darkstores" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}
><img src="img/icon6.png" alt=""/> Dark Stores</NavLink>
            
            </li>
            */ }
            <li><hr/></li>

           
            
          
            {

                /*

 <li>
            <NavLink to="/companies" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}
><img src="img/icon7.png" alt=""/> Companies</NavLink>
              </li>  */  
            }

<li>
            <NavLink to="/businesscustomers" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}
><img src="img/icon8.png" alt=""/>  {t('BusinessCustomers')} </NavLink>
            </li>
<li>
            <NavLink to="/branches" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}
><img src="img/icon6.png" alt=""/> {t('Branches')}</NavLink>
            </li>

            
            

            <li><hr/></li>
            <li>
            <NavLink to="/b2busers" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}
><img src="img/icon7.png" alt=""/> {t('B2BUsers')}</NavLink>
            
            </li>

{/*  <li>
            <NavLink to="/" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}
><img src="img/icon9.png" alt=""/>System Parameters </NavLink>
            
            </li>*/}
            

        </ul>


        <a class="logout" onClick={onLogout}><img src="img/logout.png" alt=""/>{t('Logout')}</a>

    </div>
    );
  }
  
  export default Sidebar;
  