// Core i18next library.
import i18n from "i18next";                      
// Bindings for React: allow components to
// re-render when language changes.
import { initReactI18next } from "react-i18next";

import { useTranslation } from 'react-i18next';
i18n
  // Add React bindings as a plugin.
  .use(initReactI18next)
  // Initialize the i18next instance.
  .init({
    // Config options

    // Specifies the default language (locale) used
    // when a user visits our site for the first time.
    // We use English here, but feel free to use
    // whichever locale you want.                   
    lng: "en",

    // Fallback locale used when a translation is
    // missing in the active locale. Again, use your
    // preferred locale here. 
    fallbackLng: "en",

    // Enables useful output in the browser’s
    // dev console.
    debug: true,

    // Normally, we want `escapeValue: true` as it
    // ensures that i18next escapes any code in
    // translation messages, safeguarding against
    // XSS (cross-site scripting) attacks. However,
    // React does this escaping itself, so we turn 
    // it off in i18next.
    interpolation: {
      escapeValue: false,
    },

    // Translation messages. Add any languages
    // you want here.
    resources: {
      // English
      en: {
        // `translation` is the default namespace.
        // More details about namespaces shortly.
        translation: {
          hello_world: "Hello, World!",
          Dashboard: "Dashboard",
          Products: "Products",
          Summary: "Summary",
          FavLists: "Fav Lists",
          Orders: "Orders",
          Finances: "Finances",
          BusinessCustomers: "Business Customers",
          Branches: "Branches",
          B2BUsers: "B2B Users",
          Logout: "Logout",
          loginwelcome:"Welcome To B2B",
          logintitle: "Login - Admin",
          username: "Username",
          password: "Password",
          loginbutton: "Login",
          MainDashboard:"Main Dashboard",
          RecentOrders:"Recent Orders",
          RecentRFQs:"Recent RFQs",
          OverallOrders:"Overall Orders",
          TotalOrderValue:"Total Order Value",
          OpenOrderValue:"Open Order Value",
          TotalAdminUser:"Total Admin User",
          TotalBranches:"Total Branches",
          Opened:"Opened",
          Submitted:"Submitted",
          Completed:"Completed",
          Cancelled:"Cancelled",
          Product:"Product",
          PendingSubmission:"Pending Submission",
          QuoteSubmitted:"Quote Submitted",
          Submitted:"Submitted",
          Insights:"Insights",
          ManageOrders:"Manage Orders",
          CreateNewOrder:"Create New Order",
          ManageBusinessCustomers:"Manage Business Customers(BC)",
          Branch:"Branch",
          ManageB2BUsers:"Manage B2B Users",
          AddNewBranch:"Add New Branch",
          TotalInvoiceValue:"Total Invoice Value",
          OpenInvoiceValue:"Open Invoice Value",
          PaidInvoices:"Paid Invoices",
          UnpaidInvoices:"Unpaid Invoices",
          Details: "Details",
          ProductDetails: "Product Details",
          BarCode: "Bar Code",
          ProductCategory: "Product Category",
          UnitOfMeasure: "Unit Of Measure",
          Price: "Price",
          Description: "Description",
          ProductImg: "Product Img",
          Upload: "Upload",
          Show: "Show",
          Deliverydetails: "Delivery details",
          Order: "Order",
          OrderDate: "Order Date",
          ExpectedDeliveryDays: "ExpectedDeliveryDays",
          DeliveryCharges: "DeliveryCharges",
          DarkStore: "Dark Store",
          ConfirmPayment: "Confirm Payment",
          BranchInfo: "Branch Info",
          BranchNameInEnglish: "Branch Name In English",
          BranchnameinArabic: "Branch name in Arabic",
          Emailwithusername: "Email with username",
          MobileNumber: "Mobile Number",
          WhatsAppNumber:"WhatsApp Number",
          Address: "Address",
          AddressLink: "Address Link",
          Description: "Description",
          QoyodCustomerMap: "Qoyod Customer Map",
          Status: "Status",
          Save: "Save",
          BranchDetail: "Branch Detail",
          CommercialRegistration: "Commercial Registration",
          BranchUsers: "Branch Users",
          BusinessCustomerProfile: "Business Customer Profile",
          GeneralDetails: "General Details",
          CustomerNameInEnglish: "Customer Name In English",
          CustomerNameInArabic: "Customer Name In Arabic", 
          CustomerEmail: "Customer Email",
          CustomerCategory: "Customer Category",
          MainPersonName: "Main Person Name",
          BusinessLogo: "Business Logo",
          TaxFile: "Tax File",
          Cancel: "Cancel",
          Saveinformation: "Save information",
          ManageBranchForBusinessCustomer: "Manage Branch For Business Customer",
          OrderID: "Order ID",
          Branch: "Branch",
          DeliveryType: "Delivery Type",
          QoyodInvId: "Qoyod Inv Id",
          PaymentStatus: "Payment Status",
          InvoiceStatus: "Invoice Status",
          Totalamount: "Total amount",
          FavListName: "Fav List Name",
          Date: "Date",
          DeliveryPriority: "Delivery Priority",
          Product: "Product",
          Category: "Category",
          RetailPrice: "Retail Price",
          Order: "Order",
          Amount: "Amount",
          CreationDate: "Creation Date",
          CanBeCreatedFromRegistrationPageOf: "Can Be Created From Registration Page Of ",
          BranchesCanAlsoBeCreatedFromRegistrationPageOfBranch: "Branches Can Also Be Created From Registration Page Of Branch",
          Email: "Email",
          QoyodCustMap: "Qoyod Cust Map",
          Approved: "Approved",
          Addnewbranch: "Add new branch",
          Name: "Name",
          Superuser: "Superuser",
          BranchnameinArabic: "Branch name in Arabic",
          BranchInfo: "Branch Info",
          BranchNameinEnglish: "Branch Name in English",
          BranchManagerEmail: "Branch Manager Email",
          BusinessCustomer: "Business Customer",
          QoyodCustomerMap: "Qoyod Customer Map",
          ManagerNameInEnglish: "Manager Name In English",
          ManagerNameInArabic: "Manager Name In Arabic",
          Password: "Password",
          RePassword: "Re-Password", 
          CreateBranch: "Create Branch",
          NewBranch: "New Branch",
          FavListsDetailfor: "Fav Lists Detail for",
          lineitem: "line item",
          WaitingForQuoteSubmissioByBranch: "Waiting For Quote Submission By Branch",
          FavouriteList: "Favourite List",
          RFQDetails: "RFQ Details",
          Branch: "Branch",
          DeliveryAddress: "Delivery Address",
          ExpectedDeliveryDays: "Expected Delivery Days",
          DeliveryCharges: "Delivery Charges",
          Summary: "Summary",
          ShowList: "Show List",
          TotalAmount: "Total Amount",
          Quantity: "Quantity",
          RetailPriceSAR: "Retail Price (SAR)",
          UpdatedPriceSAR: "Updated Price (SAR)",
          VAT15: "VAT (15%)",
          CreateNewOrder: "Create New Order",
          AddProducts: "Add Products",
          Next: "Next",
          OrderDetails: "Order Details",
          TotalamountExclVAT: "Total amount Excl VAT",
          VATPER: "VAT PER",
          GrandTotal: "Grand Total",
          UnitPriceSAR: "Unit Price (SAR)",
          TotalPriceIncVAT: "Total Price Inc VAT",
          BCCanBeCreatedFromRegistrationPageOfBC: "BC Can Be Created From Registration Page Of BC.",
          ClickHere: "Click Here",
          Paid: "Paid",
          Completed: "Completed",
          Normaldelivery: "Normal Delivery",
          ProductNameEnglish: "Product Name [English]",
          ProductNameArabic: "Product Name [Arabic]",
          Quotes: "Quotes",
          Type: "Type",
          InternalReferences: "Internal references",
          Cost: "Cost"
        },
      },
      // Arabic
      ar: {
        translation: {
          hello_world: "مرحباً بالعالم!",
          OrderDetails: "تفاصيل الطلب",
          Dashboard: "لوحة التحكم",
          Products: "المنتجات",
          Product: "منتج",
          Category: "فئة",
          Paid: "مدفوع",
          Normaldelivery: "توصيلة طبيعية",
          Quotes: "يقتبس",
          ProductNameArabic: "اسم المنتج [عربي]",
          Completed: "مكتمل",
          ProductNameEnglish: "اسم المنتج [إنجليزي]",
          VATPER: "ضريبة القيمة المضافة لكل",
          ClickHere: "انقر هنا",
          TotalPriceIncVAT: "السعر الإجمالي شامل ضريبة القيمة المضافة",
          GrandTotal: "المجموع الإجمالي",
          CreateNewOrder: "إنشاء طلب جديد",
          BCCanBeCreatedFromRegistrationPageOfBC: "يمكن إنشاء BC من صفحة التسجيل الخاصة بـ BC",
          VAT15: "ضريبة القيمة المضافة (15%)",
          UnitPriceSAR: "سعر الوحدة (ريال)",
          TotalamountExclVAT: "المبلغ الإجمالي غير شامل ضريبة القيمة المضافة",
          AddProducts: "أضف المنتجات",
          UpdatedPriceSAR: "السعر المحدث (ريال سعودي)",
          RetailPriceSAR: "سعر التجزئة (ريال سعودي)",
          CreateBranch: "إنشاء فرع",
          Quantity: "كمية",
          TotalAmount: "المبلغ الإجمالي",
          FavouriteList: "قائمة المفضلة",
          ShowList: "عرض قائمة",
          DeliveryCharges: "رسوم التوصيل",
          DeliveryAddress: "عنوان التسليم",
          Summary: "ملخص",
          ExpectedDeliveryDays: "أيام التسليم المتوقعة",
          Branch: "فرع",
          RFQDetails: "تفاصيل طلب عرض الأسعار",
          WaitingForQuoteSubmissioByBranch: "في انتظار تقديم عرض الأسعار حسب الفرع",
          lineitem: "خط سلعة",
          FavListsDetailfor: "تفاصيل قوائم المفضلة لـ",
          NewBranch: "فرع جديد",
          BusinessCustomer: "عملاء الأعمال",
          QoyodCustomerMap: "خريطة عملاء قيود",
          ManagerNameInArabic: "اسم المدير باللغة العربية",
          ManagerNameInEnglish: "اسم المدير بالانجليزية",
          BranchManagerEmail: "البريد الإلكتروني لمدير الفرع",
          BranchInfo: "معلومات الفرع",
          Password: "كلمة المرور",
          RePassword: "إعادة كلمة المرور",
          BranchNameinEnglish: "اسم الفرع باللغة الانجليزية",
          BranchnameinArabic: "اسم الفرع باللغه العربيه",
          Superuser: "المستخدم المتميز",
          Email: "بريد إلكتروني",
          Approved: "موافقة",
          QoyodCustMap: "خريطة قيود",
          CreationDate: "تاريخ الإنشاء",
          Name: "اسم",
          Amount: "كمية",
          BranchesCanAlsoBeCreatedFromRegistrationPageOfBranch: "يمكن أيضًا إنشاء الفروع من صفحة تسجيل الفرع",
          CanBeCreatedFromRegistrationPageOf: "يمكن إنشاؤها من صفحة التسجيل الخاصة بـ ",
          RetailPrice: "سعر التجزئة",
          Addnewbranch: "إضافة فرع جديد",
          ProductDetails: "تفاصيل المنتج",
          Totalamount: "المبلغ الإجمالي",
          BarCode: "الباركود",
          BranchInfo: "معلومات الفرع",
          Date: "تاريخ",
          Order: "طلب",
          DeliveryPriority: "أولوية التسليم",
          QoyodInvId: "معرف قيود للاستثمار",
          FavListName: "اسم القائمة المفضلة",
          DeliveryType: "نوع التوصيل",
          PaymentStatus: "حالة السداد",
          InvoiceStatus: "حالة الفاتورة",
          Branch: "فرع",
          Status: "حالة",
          Save: "يحفظ",
          OrderID: "رقم التعريف الخاص بالطلب",
          TaxFile: "الملف الضريبي",
          BusinessLogo: "شعار الأعمال",
          Cancel: "يلغي",
          ManageBranchForBusinessCustomer: "إدارة الفرع لعملاء الأعمال",
          CommercialRegistration: "التسجيل التجاري",
          CustomerEmail: "البريد الإلكتروني للعميل",
          CustomerCategory: "فئة العملاء",
          Saveinformation: "حفظ المعلومات",
          MainPersonName: "اسم الشخص الرئيسي",
          CustomerNameInArabic: "اسم العميل باللغة العربية",
          GeneralDetails: "تفاصيل عامة",
          CustomerNameInEnglish: "اسم العميل باللغة الإنجليزية",
          BusinessCustomerProfile: "ملف تعريف العملاء التجاريين",
          BranchUsers: "مستخدمي الفروع",
          BranchDetail: "تفاصيل الفرع",
          BranchNameinEnglish: "اسم الفرع باللغة الانجليزية",
          BranchnameinArabic: "اسم الفرع باللغه العربيه",
          ProductCategory: "فئة المنتج",
          ConfirmPayment: "تأكيد الدفع",
          Emailwithusername: "البريد الإلكتروني مع اسم المستخدم",
          UnitOfMeasure: "وحدة القياس",
          MobileNumber: "رقم الهاتف المحمول",
          WhatsAppNumber:"رقم الواتساب",
          FavLists: "قائمة المفضلة",
          QoyodCustomerMap: "خريطة عملاء قيود",
          Summary: "ملخص",
          Address: "عنوان",
          AddressLink: "رابط العنوان",
          Description: "وصف",
          ExpectedDeliveryDays: "أيام التسليم المتوقعة",
          DeliveryCharges: "رسوم التوصيل",
          Deliverydetails: "تفاصيل التسليم",
          Order: "طلب",
          DarkStore: "متجر الظلام",
          OrderDate: "تاريخ الطلب",
          Orders: "الطلبات",
          Description: "وصف",
          Finances: "الحسابات",
          ProductImg: "صورة المنتج",
          Price: "سعر",
          BusinessCustomers: "العملاء",
          Branches: "الفروع",
          B2BUsers: "المستخدمين",
          Upload: "رفع",
          Show: "يعرض",
          Logout: "تسجيل خروج",
          loginwelcome:"Welcome To B2B",
          logintitle: "Login - Admin",
          username: "Username",
          password: "Password",
          loginbutton: "تسجيل الدخول",
          MainDashboard:"لوحة التحكم",
          RecentOrders:"الطلبيات الأخيرة",
          RecentRFQs:"طلبات عروض الأسعار الأخيرة",
          OverallOrders:"الطلبات الشاملة",
          TotalOrderValue:"إجمالي قيمة الطلب",
          OpenOrderValue:"فتح قيمة الطلب",
          TotalAdminUser:"اجمالي عدد الادمن",
          TotalBranches:"إجمالي الفروع",
          Opened:"مفتوح",
          Submitted:"تم الارسال ",
          Completed:"مكتمل",
          Cancelled:"ملغى",
          Product:"منتج",
          PendingSubmission:"في انتظار التقديم",
          QuoteSubmitted:"تم تقديم العرض",
          Submitted:"مرسل",
          Insights:"افكار",
          ManageOrders:"إدارة الطلبات",
          CreateNewOrder:"انشاء طلب جديد",
          ManageBusinessCustomers:"إدارة عملاء الأعمال (BC)",
          Branch:"الفرع",
          ManageB2BUsers:"ادارة مستخدمين",
          AddNewBranch:"اضافة فرع جديد",
          TotalInvoiceValue:"بلغ إجمالي قيمة الفاتورة",
          OpenInvoiceValue:"فتح قيمة الفاتورة",
          PaidInvoices:"الفواتير المدفوعة",
          UnpaidInvoices:"فواتير غير مدفوعة",
          Details: "تفاصيل",
          Next: "التالي",
          Type: "النوع",
          InternalReferences: "مراجع داخلي",
          Cost: "التكلفة"
        },
      },
    },
  });

export default i18n;