import '../../App.css';
import React, { useState, useEffect } from 'react';
import Maindash from '../../component/dashboard/maindash';
import Overalldash from '../../component/dashboard/overalldash';
import Top10 from '../../component/dashboard/top10';
import Top10Other from '../../component/dashboard/top10other';

import { client,clientmain } from '../../config/datasource';

import api from '../../service/api';

import { useTranslation } from "react-i18next";

function Dashboard() {


    const { i18n,t } = useTranslation();
    
	const [overalldash, setOveralldash] = useState([]);
	const [top10, setTop10] = useState([]);
	const [top10RFQ, setTop10RFQ] = useState([]);

	const [list, setList] = useState([]);
    const [total, setTotal] = useState(0);

    const [iserror, setIsError] = useState(0);
    const [open, setOpen] = useState(0);
    const [submitted, setSubmitted] = useState(0);
    const [completed, setCompleted] = useState(0);
    const [cancelled, setCancelled] = useState(0);

    const [mainDash, setMaindash] = useState(0);

	useEffect(() => {
        

		
		const fetchList = async () => {
            try {
                api.generic('GET','/general/ordersstats')               
                .then((response)=>{
                    
                    console.log(response.data);
                    setList(response.data);
                    setTotal(response.data.data.length);
                    console.log(response.data.data.filter(value => value.name === 'Opened')[0])
                    console.log(response.data.data.filter(value => value.name === 'Opened')[0].count)
                    console.log("as above")

                    setOpen(response.data.data.filter(value => value.name === 'Opened')[0].count);
                    setSubmitted(response.data.data.filter(value => value.name === 'Submitted')[0].count);
                    setCompleted(response.data.data.filter(value => value.name === 'Completed')[0].count);
                    setCancelled(response.data.data.filter(value => value.name === 'Cancelled')[0].count);

                    setOveralldash(response.data);

                })
                .catch((error) => {
                    setIsError(1);
                })
            } catch (error) {
                setIsError(1);
            }
        };


        fetchList();

		

        const fetchTop10 = async () => {


            
			try {
                api.generic('GET','/general/dashboard/recentordersbybranch')               
                .then((response)=>{
					console.log(response.data)
					setTop10(response.data);
			

                })
                .catch((error) => {
                    setIsError(1);
                })
            } catch (error) {
                setIsError(1);
            }

		};
        

        const fetchTop10RFQ = async () => {


            
            try {
                api.generic('GET','/general/dashboard/recentrfqsbybranch')               
                .then((response)=>{
                    console.log(response.data)
                    setTop10RFQ(response.data);
            
    
                })
                .catch((error) => {
                    setIsError(1);
                })
            } catch (error) {
                setIsError(1);
            }
    
        };


        fetchTop10();
        fetchTop10RFQ();
	
	}, []);


    
    return (
        <>
               
                    <h1>{t("MainDashboard")}</h1>

					<Maindash  submitted={submitted} open={open}  completed={completed} cancelled={cancelled} t={t}/>
                    <Overalldash param={overalldash}  t={t}  />


                    <div class="top10 col-xs-12">
                        <Top10 param={top10} t={t} />
                        <Top10Other param={top10RFQ} t={t} />
                    </div>

        </>
    );
}

export default Dashboard;
