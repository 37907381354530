import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
function LanguageSwitcher() {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
  };

  return (
    <div>
      {/* <span>{t('hello_world')}</span> */}
      <div className="dropdown">
        <button className="btn dropdown-toggle" type="button" id="languageDropdown" data-bs-toggle="dropdown" aria-expanded="false">
          {selectedLanguage.toUpperCase()}
        </button>
        <ul className="dropdown-menu" aria-labelledby="languageDropdown">
          <li><button className="dropdown-item" onClick={() => handleLanguageChange('en')}>English</button></li>
          <li><button className="dropdown-item" onClick={() => handleLanguageChange('ar')}>Arabic</button></li>
        </ul>
      </div>
    </div>
  );
}
export default LanguageSwitcher;
