import { useTranslation } from 'react-i18next';

function Top10Other(props) {
    
    const { t, i18n } = useTranslation();
    return (
        <div class="boxs"> 
        <h5> {props.t("RecentRFQs")}</h5>
        <table width="100%" class="table responsive-table">
            <thead>
                <tr>
                    <th> #No</th>
                    <th> {t("Branch")}</th>
                    <th> {t("Status")}</th>
                    <th> {t("Date")}</th>
                </tr>
            </thead>
            <tbody>

            {props.param.map((data) => {
					return (
                        <tr>
                        <td> <b>#00{data.Id} </b></td>
                        <td>  {data.BranchName} </td>
                        <td>  {data.Status} </td>
                        <td> {new Intl.DateTimeFormat('en-GB', {
                                        month: 'long',
                                        day: '2-digit',
                                        year: 'numeric',
                                    }).format(new Date(data.CreatedOn))} </td>
                    </tr>
					);
				})}


            

            </tbody>
        </table>
    </div>
    );
  }
  
  export default Top10Other;
  