import { Routes, Route, useNavigate, NavLink } from 'react-router-dom';
import React, { useState, useMemo, useEffect } from 'react';
import Pagination from '../common/pagination';
import data from '../../data/mock-data.json';
import { RESOURCE_URL } from "../../config/datasource";
import { useTranslation } from 'react-i18next';

function Productlist(props) {
    const { t, i18n } = useTranslation();

    let PageSize = props.pagesize;

    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(0);
    const [isData, setIsData] = useState(0);

    const [productFilter, setProductFilter, productFilterRef] = useState('');
    const [categoryFilter, setCategoryFilter, setCategoryFilterRef] = useState('');
    const [productFilterCount, setProductFilterCount, productFilterCountRef] = useState(0);

    const placeholderImage =
        'img/featured.png'

    const navigate = useNavigate();

    /*
    useEffect(() => {
    }, [props]);    
    */


    const navigatetodetail = (Id) => {
        navigate(`/productdetail/${Id}`, { state: { id: Id } });
    };


    useEffect(() => {
        console.log(props.listother)
    }, [props]);

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;

        setIsData(isData + 1);
        if (props.isError == 1) {
            setIsLoading(1);
        }

        if (isData >= 2) {
            setIsLoading(1);
        }

        if (productFilter.length > 0) {

            let products_length = 0;

            const filteredByName = props.list.products.filter(product =>
                product.name_en.toLowerCase().includes(productFilter.toLowerCase())
            );

            if (filteredByName.length > 0) {
                products_length = filteredByName.length;
                setProductFilterCount(products_length)
                return filteredByName ? filteredByName : [];
            } else {
                const filteredBySku = props.list.products.filter(product =>
                    product.sku.toLowerCase().includes(productFilter.toLowerCase())
                );
                products_length = filteredBySku.length;
                setProductFilterCount(products_length)
                return filteredBySku ? filteredBySku : [];
            }

            // setProductFilterCount(props.list.products.filter(key => key.name_en.toLowerCase().includes(productFilter.toLowerCase())) ? props.list.products.filter(key => key.name_en.toLowerCase().includes(productFilter.toLowerCase())).length : 0)
        } else {
            setProductFilterCount(props.list.products ? props.list.products.length : 0)
            if (categoryFilter.length > 0 && categoryFilter != -1) {
                return props.list.products ? props.list.products.filter(key => key.category_id == categoryFilter).slice(firstPageIndex, lastPageIndex) : [];
            } else {
                return props.list.products ? props.list.products.slice(firstPageIndex, lastPageIndex) : [];
            }
        }
    }, [currentPage, props, productFilter, categoryFilter]);

    const onImageError = (e) => {
        e.target.src = placeholderImage
    }

    return (
        <div className="boxs col-xs-12">
            <h5>
                <span><img src="img/icon2.png" alt="" /> {props.total} {t("Product")}</span>
                <div className="filters-top col-xs-12 col-md-7">
                    <form>

                        <input type="text" className="search" placeholder="Search" onChange={(e) => { setProductFilter(e.target.value); setCategoryFilter(-1) }} />
                        <select class="category" onChange={(e) => { setCategoryFilter(e.target.value); setProductFilter('') }}>
                            {props.listcategory.categories?.map(data => {
                                return (
                                    <option value={data.id}>{data.name}</option>
                                );
                            })}
                        </select>
                    </form>
                </div>
                <h1>
                    <NavLink to="/add-product">
                        <button class="add-product">
                            {t('AddProducts')}
                        </button>
                    </NavLink>
                </h1>
            </h5>


            <table width="100%" class="table responsive-table">
                <thead>
                    <tr>
                        <th> ID</th>
                        <th> SKU</th>
                        <th> {t("Product")}</th>
                        <th> {t("BarCode")}</th>
                        <th> {t("Category")}</th>
                        <th> UoM</th>
                        <th> Inv Qty</th>
                        <th> {t("RetailPrice")}</th>
                        <th> </th>
                    </tr>
                </thead>
                <tbody>



                    {Array.isArray(currentTableData) && currentTableData.length > 0 ? (
                        currentTableData.map(data => {
                            return (
                                <tr key={data.id}>
                                    <td className="bold">{data.id} </td>
                                    <td className="bold">{data.sku} </td>
                                    <td style={{ textAlign: 'left' }}>
                                        <img
                                            src={RESOURCE_URL + '/product/' + data.id + '_img.png' ? RESOURCE_URL + '/product/' + data.id + '_img.png' : placeholderImage}
                                            alt=""
                                            onError={onImageError}
                                        />
                                        <b>{data.name_en}  </b></td>
                                    <td>{data.barcode}</td>
                                    <td className="bold">

                                        {Array.isArray(props.listcategory?.categories) && props.listcategory?.categories.length > 0 ? (
                                            props.listcategory?.categories.filter(cat => cat.id == data.category_id).map(fillcat => (<span>{fillcat.name}</span>))
                                        ) : (<p></p>)}

                                    </td>
                                    <td>{data.unit}</td>
                                    <td>{data.inventories[0]?.stock}</td>
                                    <td>SAR {data.selling_price}</td>
                                    <td><a onClick={() => navigatetodetail(data.id)}><i class="fa fa-eye"></i></a></td>
                                </tr>

                            );
                        })
                    ) : (<p></p>)}



                </tbody>
            </table>
            {isLoading == 0 ? <h1 align='center'>Loading..</h1> : null}
            {/*
        <div className="nav-page col-xs-12">
            <a className="previous" href="#">Previous</a>
            <ul>
                {
    new Array(props.total).fill(0).map((_, index) => (
        index == 0 ? (
            <li><a href='#' key={index} className="active" >{index+1}</a></li>
          ) : (
            <li><a href='#' key={index} >{index+1}</a></li>
          )

        
    ))
}

            </ul>
            <a className="next" href="#">Next</a>
        </div>

*/}


            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={productFilterCount}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
            />
        </div>
    );
}

export default Productlist;
