import React from "react";
import { Chart } from "react-google-charts";
import {  useEffect } from "react";
import useState from 'react-usestateref' 



function Overalldash(props) {
   
	const [graphData, setGraphData] = useState([]);
    const[total,setTotal] = useState(0)
    const[filter,setFilter, filterRef] = useState(2)

    function getMonthName(month){
        const d = new Date();
        d.setMonth(month-1);
        const monthName = d.toLocaleString("default", {month: "long"});
        return monthName;
      }

/*
     const data = [
        ["Month", "Amount"],
        ["SEP", 100],
        ["OCT", 1170],
        ["NOV", 660],
        ["DEC", 1030],
      ];
      */

       const options = {
        title: props.t("OverallOrders") ,
        hAxis: { title: "Month", titleTextStyle: { color: "#333" } },
        vAxis: { minValue: 0 },
        chartArea: { width: "70%", height: "70%" },
      };

      
      useEffect(() => {
       loadfilter()
        
      }, [props] )

      

    console.log(props)

    function loadfilter() {
        const data=[]
        var total=0
       console.log(filter)
        if(filterRef.current==2) {
            console.log("loading month wise graph")
        if(props?.param?.datamonth) {
            props.param.datamonth[0].map((e, i) => {
                if(i==0) {
                    data.push(["Month","Sales"])
                }
                data.push([getMonthName(e.Month),Number(e.Amount)])
            });


              props.param.datamonth[0].map((e, i) => {
               // if(i!=0) {
                    total=total+ Number(e.Amount)
              //  } 
            }
              );
              setTotal(total.toFixed(2))
              console.log("ONE")
            console.log(data)
            setGraphData(data)
    }
} 

if(filterRef.current==3) {
    
    console.log("loading current month graph")
    if(props?.param?.datacurrentmonth) {
        props.param.datacurrentmonth[0].map((e, i) => {
            if(i==0) {
                data.push(["Day","Sales"])
            }
            data.push([e.Day,Number(e.Amount)])
        });


          props.param.datacurrentmonth[0].map((e, i) => {
           // if(i!=0) {
                total=total+ Number(e.Amount)
           // } 
        }
          );
          setTotal(total.toFixed(2))
        setGraphData(data)
        
        console.log("TWO")
}
}


if(filterRef.current==0) {
    
    console.log("loading todays graph")
    if(props?.param?.datatoday) {
        props.param.datatoday[0].map((e, i) => {
            if(i==0) {
                data.push(["Day","Sales"])
            }
            data.push([e.Day,Number(e.Amount)])
        });


          props.param.datatoday[0].map((e, i) => {
           // if(i!=0) {
                total=total+ Number(e.Amount)
         //   } 
        }
          );
          setTotal(total.toFixed(2))
        setGraphData(data)
        
        console.log("TWO")
}
}



if(filterRef.current==1) {
    
    console.log("loading 7 days graph")
    if(props?.param?.datasevendays) {
        props.param.datasevendays[0].map((e, i) => {
            if(i==0) {
                data.push(["Day","Sales"])
            }
            data.push([e.Day,Number(e.Amount)])
        });


          props.param.datasevendays[0].map((e, i) => {
           // if(i!=0) {
                total=total+ Number(e.Amount)
         //   } 
        }
          );
          setTotal(total.toFixed(2))
         setGraphData(data)
        
        console.log("TWO")
}
}

    }

    return (
        <div class="chart col-12-xs">
        <div class="boxs chart-box">
            <div class="tit col-xs-12">{props.t("OverallOrders")}  
                <select name="dashboardFilter" id="dashboardFilter" onChange={(e) => {
  setFilter(e.target.value)
  loadfilter()
   console.log('event value', e.target.value);
   console.log('event name', e.target.name);
  }}>
                    <option value="0">Today</option>
                    <option value="1">Last 7 Days</option>
                    <option value="3">This Month</option>
                    <option value="2" selected>Month Wise</option>
                </select>
            </div>
            <h1> {total}</h1>
             {  /*
             <img src="img/chart.png" alt=""/> */
            }
<div style={{"width":"100%"}}>  <Chart
      chartType="AreaChart"
      width="100%"
      height="400px"
      data={graphData}
      options={options}
    />
</div>

        </div>
        <div class="box">
            <div class="boxs">
                <img src="img/chart1.png" alt=""/>
                <div>{props.t("TotalOrderValue")} <h3>SAR {props?.param?.dataother?props?.param?.dataother[0]?.val:0}</h3></div>
            </div>
            <div class="boxs">
                <img src="img/chart2.png" alt=""/>
                <div>{props.t("OpenOrderValue")} <h3>SAR {props?.param?.dataother?props?.param?.dataother[1]?.val:0}</h3></div>
            </div>
            <div class="boxs">
                <img src="img/chart3.png" alt=""/>
                <div>{props.t("TotalAdminUser")}  <h3>{props?.param?.dataother?props?.param?.dataother[3]?.val:0}</h3></div>
            </div> <div class="boxs">
                <img src="img/chart3.png" alt=""/>
                <div>{props.t("TotalBranches")}  <h3>{props?.param?.dataother?props?.param?.dataother[4]?.val:0}</h3></div>
            </div>
        </div>
     </div>
    );
  }
  
  export default Overalldash;
  