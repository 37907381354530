import React, { useState, useEffect } from 'react';
import List from '../../component/darkstores/darkstoreslist';
import api from '../../service/api';


function Darkstores() { 

    

    const [list, setList] = useState([]);
    const [listCategory, setListCategory] = useState([]);
    const [listOther, setListOther] = useState([]);
    const [total, setTotal] = useState(0);
    const [isError, setIsError] = useState(0);
        
    useEffect(() => {
            

        const fetchList = async () => {
            try {
                api.generic('GET','/general/stores')               
                .then((response)=>{
                    
                    console.log(response.data);
                    setList(response.data);
                    setTotal(response.data.data.length);
                })
                .catch((error) => {
                    setIsError(1);
                })
            } catch (error) {
                setIsError(1);
            }
        };


        fetchList();



        
    }, []);



    return ( 
    <>
   <h1>Dark Stores
         {/*   <button  class="add-product"><span>+</span>Add Dark Stores</button> */ }
         </h1>

     <div class="product col-xs-12">
        
        
     <List list={list} total={total} pagesize={15} isError={isError} />
     </div>
   </>
    ); 
} 
export default Darkstores; 