
import React, { useState, useMemo, useEffect } from 'react';
import Pagination from '../common/pagination';
import data from '../../data/mock-data.json';

function Companylist(props) {
    let PageSize = props.pagesize;

    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(0);
    const [isData, setIsData] = useState(0);


    /*
    useEffect(() => {
    }, [props]);    
    */


    const currentTableData = useMemo(() => {
        console.log("worked")

        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;

        setIsData(isData + 1);
        if (props.isError == 1) {
            setIsLoading(1);
        }

        if (isData >= 1) {
            setIsLoading(1);
        }

        return props.list.data ? props.list.data.slice(firstPageIndex, lastPageIndex) : [];


    }, [currentPage, props]);

    return (
        <div className="boxs col-xs-12">
            <h5>
                <span><img src="img/icon2.png" alt="" /> {props.total} Company</span>



                <div className="filters-top col-xs-12 col-md-7">

                </div>

            </h5>



            <table width="100%" class="table responsive-table">
                <thead>
                    <tr>
                        <th>Company</th>
                    </tr>
                </thead>
                <tbody>



                    {currentTableData.map(data => {
                        return (
                            <tr key={data.Id}>
                                <td>{data.ArName}</td>

                            </tr>

                        );
                    })}

                </tbody>
            </table>

            {isLoading == 0 ? <h1 align='center'>Loading..</h1> : null}



            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={props.total}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
            />
        </div>


    );
}

export default Companylist;
