
import React, { useState, useMemo, useEffect } from 'react';
import Pagination from '../common/pagination';
import data from '../../data/mock-data.json';
import {Routes, Route, useNavigate} from 'react-router-dom';

import Popup from 'reactjs-popup';

import 'reactjs-popup/dist/index.css';
import xor from 'lodash/xor';

import xorBy from 'lodash/xor';


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import api from '../../service/api';

import { useDashboardStore } from '../../store/useDashboardStore';

import { RESOURCE_URL } from "../../config/datasource";

function ProductlistforOrders(props) {



let PageSize = props.pagesize;

const [currentPage, setCurrentPage] = useState(1);
const [isLoading, setIsLoading] = useState(0);
const [isData, setIsData] = useState(0);
const [checked, setChecked] = useState([]);  
const [inputListName, setInputListName] = useState('');
const [open, setOpen] = useState(false);
const [qty, setQty] = useState(1);

const [proqty, setProQty] = useState([]);  

let [isOpen, setIsOpen] = useState(true)



const placeholderImage =
'img/featured.png'

const navigate = useNavigate();


const {id,neworderinitdata,orderproducts,addOrderProducts,removeOrderProducts} = useDashboardStore((state) => ({
  id: state.id,
  neworderinitdata: state.neworderinitdata,
  orderproducts:state.orderproducts,
  addOrderProducts: state.addOrderProducts,
  removeOrderProducts: state.removeOrderProducts,
}));

const handleQtyChange = (id,sku, e) => {
  var tempQty=[];

  tempQty=proqty.filter(a => a.ProductId !== id)
  
  setProQty([
    ...tempQty,
    {sku:sku,ProductId: id, BranchUserId:1 , qty:Number(e.target.value)}
  ]);
  //console.log(proqty)

}


useEffect(() => {
  console.log(proqty)
}, [proqty]); 

const navigatetoorders = () => {
  navigate('/orders');
}

const navigatetoordersummary = () => {

  const detail=[]
   if(checked.length>0) {

    checked.forEach((id, index) => {
      const prodata = props.list.products.filter( (pro) => pro.sku.includes(id))
      var qty=proqty.filter(a => a.ProductId == prodata[0].id)[0].qty

      addOrderProducts({sku:prodata[0].sku,ProductId: prodata[0].id, BranchUserId:1, qty:qty })
      detail.push({sku:prodata[0].sku,ProductId: prodata[0].id, BranchUserId:1, qty:qty })
      console.log({sku:prodata[0].sku,ProductId: prodata[0].id, BranchUserId:1, qty:qty })

      //var qty=proqty.filter(a => a.ProductId == prodata[0].id)[0].qty
      //detail.push({sku:prodata[0].sku,ProductId: prodata[0].id, BranchUserId:1, qty:qty })

    });
    //console.log(orderproducts)
    navigate('/ordersummary');
  } else {
  alert("Atleast select one product")
  }
}


const navigatetodetail = (Id) => {

    navigate(`/productdetail/${Id}`, { state: { id: Id } });
};

  const handleClose = () => {
    setOpen(false);
  };

  
  const handleProceed = () => {
   // setOpen(false);
   const detail=[]
   if(checked.length>0) {

    checked.forEach((id, index) => {
      const prodata = props.list.products.filter( (pro) => pro.sku.includes(id))
      //console.log(prodata[0].sku)
      var qty=proqty.filter(a => a.ProductId == prodata[0].id)[0].qty
      detail.push({sku:prodata[0].sku,ProductId: prodata[0].id, BranchUserId:1, qty:qty })
      addOrderProducts({sku:prodata[0].sku,ProductId: prodata[0].id, BranchUserId:1 , qty:qty})
    });

    console.log(detail)

 //alert(checked.length);
 //alert(checked[0].id);
    
    const data =  {
        EnName:inputListName,
        ArName:inputListName,
        BranchFavouriteListProducts: detail
      };

    try {
      //  api.generic('POST','/general/branchfavouritelist/addupdate',data)     
        api.generic('POST','/general/rfqs/addupdate',data)             
        .then((response)=>{
            navigate('/branchfavourites',{state:{load:1}});
            console.log(response.data);
        })
        .catch((error) => {
            alert("Technical issue. Please Try Again");
        })
    } catch (error) {
        alert("Technical issue. Please Try Again");
    }
    
  } else {
    alert("Couldn't create list as none of the products selected");
  }

  };

const submitFavourites = () => {
     //setOpen(true);
     handleProceed()
  };

  /*
  const handleChange = id => () => {
    setChecked(prev => {
      if (prev.includes(id)) {
        return prev.filter(x => x !== id);
      } else {
        return [...prev, id];
      }
    });
  };
*/

const handleChange = (id,sku) => () => {
  setChecked(prev => xor(prev, [sku]));
  var tempQty=[];

  tempQty=proqty.filter(a => a.ProductId !== id)
  
  setProQty([
    ...tempQty,
    {sku:sku,ProductId: id, BranchUserId:1 , qty:1}
  ]);

  };


useEffect(() => {
    console.log(props.listother)
}, [props]);   

const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    
    setIsData(isData+1);
    if(props.isError==1) {
        setIsLoading(1);
    }

    if(isData>=2) {
       setIsLoading(1);
    }

    
    return props.list.products ? props.list.products.slice(firstPageIndex, lastPageIndex) : [];

  }, [currentPage,props]);


  const onImageError = (e) => {
    e.target.src = placeholderImage
    }



    return (
        <div className="boxs col-xs-12"> 
        
        <h5> 




            <span><img src="img/icon2.png" alt=""/> {props.total} Available Products</span>

            <div class="selected">{ checked.length } selected product</div>

            <div className="filters-top col-xs-12 col-md-7">
                
                <form action="">
                    <input type="text" className="search" placeholder="Search"/>
                        <select class="category">
                        { props.listcategory.categories?.map(data => {
                            return(
                            <option value={data.id}>{data.name}</option>
                            );
                        })}
                        </select>
                        

                </form>




            </div>

        </h5>


        <table width="100%" class="table responsive-table">
            <thead>
                <tr>
                            <th><input type="checkbox" class="all"/></th>
                    <th> ID</th>
                    <th> SKU</th>
                    <th> Product</th>
                    <th> Qty</th>
                    <th> Bar Code</th>
                    <th> Category</th>
                    <th> UoM</th>
                    <th> Retail Pricing</th>
                    <th> </th>
                </tr>
            </thead>
            <tbody>



             {currentTableData.map(data => {
					return (
                        
                        <tr key={data.id}> <td><input type="checkbox"  onChange={handleChange(data.id, data.sku)}
                        checked={checked.includes(data.sku)} /></td>
                        <td className="bold">{data.id} </td>
                        <td className="bold">{data.sku} </td>
                        <td><img
              src={RESOURCE_URL +  '/product/' + data.id + '_img.png' ? RESOURCE_URL +  '/product/' + data.id + '_img.png' : placeholderImage}
              alt=""
              onError={onImageError}
            /> <b>{data.name_en} </b></td>
                        <td>

                                          <input type="number"   
 class="form-control text-center" value={proqty.filter(a => a.ProductId == data.id).length!=0?proqty.filter(a => a.ProductId == data.id)[0].qty:"0"} onChange={(e) => handleQtyChange(data.id, data.sku, e)}  />
                          
                            </td>
                        <td>{data.barcode}</td>
                        <td className="bold">
                        
                        {props.listcategory?.categories?.filter(cat=>cat.id==data.category_id  ).map(fillcat => (
     
                       <span>{fillcat.name}</span>
                          ))}
</td>
                        <td>{data.unit}</td>
                        <td>SAR {data.selling_price}</td>
                                <td><a onClick={() => navigatetodetail(data.id)}><i class="fa fa-eye"></i></a></td>
                    </tr>

					);
				})}


                
            </tbody>
        </table>

        

        {isLoading == 0 ?  <h1 align='center'>Loading..</h1> : null}
     
        <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={props.total}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)}
      />
   <div class="product col-xs-12">
            <div class="boxs create-product col-xs-12"> 
                <button  onClick={navigatetoordersummary}   class="create">Next</button>
                <button class="cancel" onClick={navigatetoorders}>Cancel</button>
            </div>
        </div>

<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleProceed} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>


   </div>
     

    );
  }
  
  export default ProductlistforOrders;
  